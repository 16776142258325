<template>
    <!-- 业主需求 -->
    <div class="item-box">
        <div class="top-line">
            <div class="tit">需求分析</div>
            <div class="right" v-if="active == '999' && list.length == 4">
                <el-button type="text" @click="handleCheckTab()" size="mini">
                    查看更多
                </el-button>
            </div>
        </div>
        <div class="infor-item">
            <sc-table-v2
                v-if="active == '6' || list.length"
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="pageHide"
                :read-table="true"
                :load="load"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :selection="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:edit>
                    <el-table-column label="操作" fixed="right" width="60">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="goDetail(scope.row)">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
            <div class="question" v-if="active == '999'">
                <demand-detail></demand-detail>
                <!-- <el-tabs v-model="tabCurrent" @tab-click="handleClick">
                    <el-tab-pane
                        :label="item.label + item.num"
                        :key="item.id"
                        :name="item.id"
                        v-for="item in tabLists"
                    ></el-tab-pane>
                </el-tabs> -->
            </div>
        </div>
        <qa-dialog ref="qaDialog"></qa-dialog>
    </div>
</template>
<script>
import { getUserQuestionList } from '@/api/mkt/user.js';
import demandDetail from './demand-detail.vue';
import qaDialog from './qa-dialog.vue';
const platformObj = {
    1: 'BPM',
    2: '尚层之家',
    3: '尚层家',
};
const nameFormatterFn = row => {
    let result = row.nickName || '' + ((row.mobileLast && '（' + row.mobileLast + '）') || '');
    return result;
};
const shareFormatterFn = row => {
    let result =
        (row.sharingName || '') + ((row.sharingAccount && '（' + row.sharingAccount + '）') || '');
    return result;
};
const answersNumFormatterFn = row => {
    let result = (row.answersNum || '--') + '/' + (row.questionTotalNum || '--');
    return result;
};
const plarformFormatterFn = row => {
    return (row.platform && platformObj[row.platform]) || '尚层家';
};
const templateTypeObj = {
    5: '个性化',
    1: '简易版',
    2: '全量版',
    3: '分公司',
};
const typeFormatterFn = row => {
    return row.templateType && templateTypeObj[row.templateType];
};

const tableHead = [
    { key: 'resultNum', name: '记录编号', width: '200', align: 'center' },
    { key: 'title', name: '模版名称' },
    { key: 'templateNum', name: '模版编号' },
    { key: 'templateType', name: '模版类型', width: '130', formatter: typeFormatterFn },
    {
        key: 'locationSource',
        name: '来源',
        width: '120',
    },
    // { key: 'createName', name: '创建人', width: '130', formatter: nameFormatterFn },
    { key: 'sharingName', name: '分享人', width: '150', formatter: shareFormatterFn },
    { key: 'createTime', name: '创建时间', width: '148' },
    { key: 'duration', name: '答题时长', width: '90' },
    { key: 'answersNum', name: '答题数量', width: '90', formatter: answersNumFormatterFn },
    { key: 'edit', name: '查看', width: '60', isShowSetHead: false },
];
export default {
    name: 'browse-list',
    components: { demandDetail, qaDialog },
    props: {
        tabActive: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tabCurrent: '11',
            tabList: [
                { label: '居住者信息', id: '11', num: '' },
                { label: '房屋信息', id: '22', num: '' },
                { label: '设计需求', id: '33', num: '' },
                { label: '大类', id: '44', num: '' },
            ],
            active: '',
            load: false,
            pageHide: false,
            tableHead: tableHead,
            tableHeightCalc: null,
            page: {
                size: 4,
                page: 1,
                count: 0,
            },
            list: [],
        };
    },

    watch: {
        tabActive: {
            immediate: true,
            handler(newVal) {
                this.list = [];
                this.active = newVal;
                if (newVal == '999') {
                    this.tableHeightCalc = null;
                    this.page.size = 4;
                    this.pageHide = true;
                } else if (newVal == '6') {
                    this.tableHeightCalc = window.innerHeight - 500;
                    this.page.size = 30;
                    this.pageHide = false;
                }
                if (this.$route.query.token) {
                    setTimeout(async () => {
                        this.getUserQuestionList();
                    }, 800);
                } else {
                    this.getUserQuestionList();
                }
            },
        },
    },
    methods: {
        async getUserQuestionList() {
            let params = {
                page: this.page.page,
                size: this.page.size,
                accountId: this.$route.query.accountId,
            };
            this.load = true;
            let { code, data } = await getUserQuestionList(params);
            if (code === 200) {
                this.load = false;
                data.children.forEach(ele => {
                    ele.browsingTimes = ele.browsingTime + 's';
                });
                this.list = data.children;
                this.page.count = data.total;

                data.total && this.$emit('setTabNum', { type: 6, num: data.total });
            } else {
                this.load = false;
            }
        },
        goDetail(row) {
            if (row.id) {
                this.$refs.qaDialog.open(row);
            }
        },
        pageChange(val) {
            this.page.page = val;
            this.getUserQuestionList();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getUserQuestionList();
        },
        handleClick(tab) {
            this.tabCurrent = tab.name;
        },

        handleCheckTab() {
            this.$parent.handleClick({ label: '需求分析', name: '6' });
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
        margin-top: 10px;
    }
}
</style>
