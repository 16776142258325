import { getList as getData } from '@/api/mkt/topic.js';
export default {
    state: {
        params: {
            size: 30,
            count: 1,
            page: 1,
        },
        page: {
            count: 0,
            page: 1,
            size: 30,
        },
        list: [],
        parseList: [],

        ossConfig: {}, //oss key
    },
    mutations: {
        setParams(state, data) {
            state.params = { ...state.params, ...data };
        },
        setList(state, data) {
            state.list = data;
        },
        setOssConfig(state, data) {
            state.ossConfig = data;
        },
        setTotal(state, data) {
            state.page.count = data;
        },
        clear(state) {
            state.list = [];
            state.params = { size: 30, page: 1 };
        },
        setParseList(state, data) {
            state.parseList = data;
        },
    },
    actions: {
        list({ commit }) {
            if (!getData) {
                return false; //
            }
            return new Promise(resolve => {
                var param = {};
                let obj = this.state['mkt-topic'].params;
                for (var key in obj) {
                    if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                        param[key] = obj[key];
                    }
                }
                console.log(param, '列表入参-article');

                getData(param).then(res => {
                    console.log('----------getData', res);
                    if (res?.isSuccess) {
                        res.data.children.map(item => {
                            Object.keys(item).forEach(items => {
                                if (item[items] === null || item[items] === '') {
                                    item[items] = '--';
                                }
                            });
                        });
                        let data = res.data || [];
                        if (data.children.length > 0) {
                            data.children.map(item => {
                                item.sourceType = item.sourceType == 2 ? '内部' : '外部';
                            });
                        }
                        commit('setList', data.children);
                        commit('setTotal', data.total);
                        resolve(data);
                    }
                });
            });
        },
        parseList({ commit }) {
            let tempData = [];
            let _list = this.state['mkt-topic'].list;
            _list.forEach(item => {
                let rowObj = {};
                for (let i in item) {
                    if (!Array.isArray(item)) {
                        let attrObj = {
                            [i]: {
                                type: 'text',
                                options: {
                                    defaultValue: item[i],
                                },
                            },
                        };
                        Object.assign(rowObj, attrObj);
                    }
                }
                tempData.push(rowObj);
            });
            console.log(tempData, 'tempData');
            commit('setParseList', tempData);
        },
    },
    getters: {
        showNum(state) {
            console.log(state, 'state');
        },
    },
};
