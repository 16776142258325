<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {};
    },
    components: {},
    methods: {},
};
</script>

<style lang="less" scoped></style>
