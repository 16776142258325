import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 获取标签组列表
export const getTagList = params =>
    $ajax({
        url: '/labels/list',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 标签组启用状态保存
//  /labels/edit-label-group-state
export const editLabelGroupState = data =>
    $ajax({
        url: '/labels/editgroupstatus',
        method: 'put',
        data,
    });
// 删除标签组  /labels/del-label-group
export const del = data =>
    $ajax({
        url: '/labels/delgroup',
        method: 'delete',
        data,
    });
// /labels/editgroup
export const editgroup = data =>
    $ajax({
        url: '/labels/editgroup',
        method: 'post',
        data,
    });

export default {};
