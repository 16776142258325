<template>
    <!-- 智能匹配/客户需求收集 -->
    <div class="item-box">
        <div class="top-line">
            <div class="tit">智能匹配</div>
            <div class="tit-right">
                <template v-if="data.intelligentMatch?.belongPage">
                    操作所属页面：{{ data.intelligentMatch.belongPage }}
                </template>
                <span>
                    <sc-qrcode-popover
                        tit="查看匹配报告"
                        :id="$route.query.accountId"
                        type="intelligent"
                    ></sc-qrcode-popover>
                </span>
            </div>
        </div>
        <div class="infor-item">
            <div class="line1">
                <div v-if="data.intelligentMatch.area">
                    您的房屋面积：{{ data.intelligentMatch.area }}
                </div>
                <div v-if="data.intelligentMatch.house">
                    您的房子是：{{ data.intelligentMatch.house }}
                </div>
                <div v-if="data.intelligentMatch.houseType">
                    居住户型：{{ data.intelligentMatch.houseType }}
                </div>
                <div v-if="data.intelligentMatch.expectedTime">
                    希望什么时候装修：{{ data.intelligentMatch.expectedTime }}
                </div>
                <div v-if="data.intelligentMatch.category">
                    装修范围：{{ data.intelligentMatch.category }}
                </div>
                <div v-if="data.intelligentMatch.residenceName">
                    您的房屋所在楼盘：{{ data.intelligentMatch.residenceName }}
                </div>
                <div v-if="data.intelligentMatch.location">
                    您的居住定位：{{ data.intelligentMatch.location }}
                </div>
                <div v-if="data.intelligentMatch.follow">
                    您在装修中最关注的是什么?：{{ data.intelligentMatch.follow }}
                </div>
                <div v-if="data.intelligentMatch.style" class="img-div">
                    <div>您喜欢下哪种风格：{{ data.intelligentMatch.style }}</div>
                    <el-image
                        v-if="data.intelligentMatch.stylePic"
                        :src="data.intelligentMatch.stylePic"
                        class="img"
                        fit="cover"
                        :preview-src-list="[data.intelligentMatch.stylePic]"
                    ></el-image>
                </div>
                <div v-if="data.intelligentMatch.designerSkills">
                    比较看重设计师哪方面？：{{ data.intelligentMatch.designerSkills }}
                </div>
            </div>
            <div class="line2">
                <div v-if="data.intelligentMatch.matchResult">
                    匹配结果：{{ data.intelligentMatch.matchResult }}
                </div>
                <div v-if="data.intelligentMatch.matchResult">
                    测试时间：{{ data.intelligentMatch.testTime }}
                </div>
            </div>

            <template v-if="data.customerDemandListVos && data.customerDemandListVos.length">
                <div class="tit">客户需求收集</div>
                <div class="line1">
                    <div v-for="(item, index) in data.customerDemandListVos" :key="index">
                        <template v-if="item.questionsName || item.optionName">
                            {{ item.questionsName }}：{{ item.optionName }}
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'inte-matching',
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
        .tit-right {
            span {
                color: #02a7f0;
                margin-left: 20px;
            }
        }
    }
    .infor-item {
        margin-top: 10px;

        .tit {
            color: #d9001b;
        }
        .line1 {
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            margin-bottom: 4px;
            color: #333333;
            line-height: 46px;
            div {
                margin-right: 14px;
            }
            .img-div {
                display: flex;
                .img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
        .line2 {
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            margin-bottom: 4px;
            color: #333333;
            line-height: 46px;
            div {
                margin-right: 14px;
            }
        }
    }
}
</style>
