import rq from '@/utils/https.js';
const $ajax = rq.requests;
import { amapKey } from '@/config.js';
//更新token

export const validToken = data => {
    return $ajax({
        url: '/login/sso',
        method: 'post',
        data,
        headers: {
            contentType: 'application/x-www-form-urlencoded',
        },
    });
};

export const getMapSearch = params => {
    return $ajax({
        url: '/user/get-place-info',
        method: 'get',
        params,
    });
};
// 获取字典数据校验
export const getDictList = params =>
    $ajax({
        url: '/dict/list/parent',
        method: 'get',
        params,
    });

// 单个图片上传
export const addFile = options =>
    $ajax({
        ...options,
        url: '/butler-system-web/upload/saveFile',
        method: 'post',
    });

// 单个图片上传
export const addFileUpload = options =>
    $ajax({
        ...options,
        url: '/product/upload/save-file',
        method: 'post',
    });

// 多个图片上传
export const addFiles = options =>
    $ajax({
        ...options,
        url: '/butler-system-web/upload/batchFiles',
        method: 'post',
    });

export default {};
