<template>
    <!-- 风格测试 -->
    <div>
        <div class="item-box">
            <div class="top-line">
                <div class="tit">风格测试</div>
                <div class="tit-right">
                    <template v-if="data?.pageBuryingPoint">
                        操作所属页面：{{ data?.pageBuryingPoint }}
                    </template>
                    <span>
                        <sc-qrcode-popover
                            tit="查看测试报告"
                            :id="$route.query.accountId"
                            type="style"
                        ></sc-qrcode-popover>
                    </span>
                </div>
            </div>
            <div class="infor-item" v-if="data?.questions?.length">
                <div class="style-box">
                    <div class="s-box" v-for="(item, index) in data?.questions" :key="index">
                        <div class="s-tit">{{ item.questionsName }}</div>
                        <div class="s-img">
                            <el-image
                                v-for="(i, ind) in item.options"
                                :src="i"
                                :key="ind"
                                class="img"
                                fit="cover"
                                lazy
                                :preview-src-list="item.options"
                            ></el-image>
                        </div>
                    </div>
                </div>

                <div class="line1">
                    <div class="cen">测试时间：{{ data.createTime }}</div>
                    <div class="right-div">
                        <div class="cen">三种偏好风格：{{ data.styleList.toString() }}</div>
                        <div class="cen" style="margin-left: 20px">
                            擅长该风格的设计师：{{ data.designers.toString() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-box">
            <div class="top-line" v-if="answers && answers.length">
                <div class="tit">装修状态</div>
            </div>
            <div class="answers-box">
                <div v-for="(item, index) in answers" :key="index">
                    <div class="answer-item" v-if="item?.optionPic?.length">
                        <span class="questions-name">{{ item.questionsName + '：' }}</span>
                        <!-- {{ item.optionPic }} -->
                        <span class="right-box">
                            <span v-for="(i, ind) in item.optionPic" :key="ind">
                                <el-image
                                    :src="i.optionPic"
                                    class="img"
                                    fit="cover"
                                    lazy
                                    :preview-src-list="item.optionPic.map(d => d.optionPic)"
                                ></el-image>
                                <div class="optionName">{{ i.optionName }}</div>
                            </span>
                        </span>
                    </div>
                    <div class="answer-item" v-else-if="item.answerName && item.questionsName">
                        {{ item.questionsName + '：' + item.answerName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'style-testing',
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        answers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
        .tit-right {
            span {
                color: #02a7f0;
                margin-left: 20px;
            }
        }
    }
    .infor-item {
        margin-top: 10px;

        .tit {
            color: #d9001b;
        }
        .line1 {
            display: flex;
            // flex-wrap: wrap;
            justify-content: space-between;
            font-size: 14px;
            margin-bottom: 4px;
            color: #333333;
            line-height: 46px;
            cen {
                margin-right: 14px;
            }
            .right-div {
                display: flex;
            }
        }
    }
}
.style-box {
    display: flex;
    flex-wrap: wrap;
    .s-box {
        margin-right: 30px;
        margin-bottom: 10px;
        .s-tit {
            color: #333;
        }
        .s-img {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            /deep/ .img {
                margin-right: 10px;
                margin-bottom: 10px;
                width: 80px;
                height: 80px;
            }
        }
    }
}
.answers-box {
    margin-top: 20px;
    display: flex;
    // flex-wrap: wrap;
    font-size: 14px;
    margin-bottom: 4px;
    color: #333333;
    line-height: 46px;
    .answer-item {
        padding-right: 30px;
        display: flex;
        .questions-name {
            flex: 1;
        }
        .right-box {
            display: flex;
            height: 100px;
            .optionName {
                line-height: 1em;
                height: 30px;
            }
        }
        /deep/ .img {
            margin-right: 10px;
            width: 80px;
            height: 80px;
        }
    }
}
</style>
