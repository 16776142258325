import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '@/vuex/store.js'

//页面组件
import error from '@/page/Error.vue'; //404页面
import login from '@/page/Login.vue'; //登录
import jumpSczshome from '@/page/Jump-sczshome.vue'; //跳转微信
import jumpScj from '@/page/Jump-scj.vue'; //跳转尚层家小程序

// 权限管理 admin
import Admin from '@/page/Admin.vue'; //admin主页
import overview_account from '@/page/public/overview/account.vue';
import demo from '@/page/mkt/demo/index2.vue';
import Bear from '@/page/Bear.vue'; //空承载页
import archivesDetail from '@/page/mkt/user/archives/detail.vue';
Vue.use(VueRouter);

export const constantRoutes = [
    // { path: '', name: '默认页面', meta: { title: '默认页面' }, component: login },
    { path: '*', meta: { title: '404错误' }, component: error },
    { path: '/404', name: '404错误', meta: { title: '404错误' }, component: error },
    {
        path: '/jump-sczshome',
        name: '登录微信',
        meta: { title: '登录微信' },
        component: jumpSczshome,
    },
    {
        path: '/jump-scj',
        name: '登录微信',
        meta: { title: '登录微信' },
        component: jumpScj,
    },
    {
        path: '/login',
        meta: { title: '登录' },
        name: '登录',
        component: login,
    },
    // 概况
    {
        path: '/userinfo',
        component: Admin,
        name: '用户信息',
        showChild: false,
        icon: 'el-icon-data-analysis',
        children: [
            {
                path: '/userinfo/account',
                component: overview_account,
                name: '账号信息',
            },
        ],
    },
    {
        path: '/archives',
        component: Bear,
        name: '用户档案统计详情',
        showChild: false,
        children: [
            {
                path: '/archives/detail',
                component: archivesDetail,
                name: '用户档案统计详情',
            },
        ],
    },
];

const createRouter = () =>
    new VueRouter({
        scrollBehavior: () => ({ y: 0 }),
        base: '/admin/menu',
        routes: constantRoutes,
    });

export const router = createRouter();

export const resetRouter = () => {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
};

export default {};
