<template>
    <!-- 点赞 收藏 评论 分享 -->
    <div class="item-box">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width="60%"
            :title="title"
            :before-close="close"
        >
            <demand-detail :id="row.id"></demand-detail>
        </el-dialog>
    </div>
</template>

<script>
import { getUserAction } from '@/api/mkt/user.js';
import demandDetail from './demand-detail.vue';

export default {
    name: 'browse-list',
    components: { demandDetail },
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    watch: {
        title: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    // this.getUserAction();
                }
            },
        },
    },
    data() {
        return {
            load: false,
            addialogVisible: false,
            tableHead: [
                { name: '互动时间', key: 'createTime' },
                { name: '内容类型', key: 'contentTypes' },
                { name: '互动内容', key: 'contentName' },
            ],
            tableHeightCalc: window.innerHeight - 420,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
            type: '',
            row: '',
        };
    },
    methods: {
        getUserAction() {
            this.load = true;
            getUserAction({
                accountId: this.$route.query.accountId,
                page: this.page.page,
                size: this.page.size,
                type: this.type,
            }).then(res => {
                if (res.code == 200) {
                    this.load = false;
                    let obj = {
                        1: '笔记',
                        2: '案例',
                        3: '灵感集',
                        4: '话题',
                        8: '作品图',
                        10: '切片',
                        11: '设计师主页',
                    };
                    res.data.children.forEach(ele => {
                        ele.contentTypes = obj[ele.contentType];
                    });
                    this.list = res.data.children;
                    this.page.count = res.data.total;
                }
            });
        },
        open(row) {
            this.addialogVisible = true;
            this.row = row;
        },
        close() {
            this.addialogVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    /deep/ .el-dialog__body {
        padding: 10px 20px !important;
    }
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
    }
}
</style>
