import { render, staticRenderFns } from "./style-testing.vue?vue&type=template&id=7ad220b6&scoped=true"
import script from "./style-testing.vue?vue&type=script&lang=js"
export * from "./style-testing.vue?vue&type=script&lang=js"
import style0 from "./style-testing.vue?vue&type=style&index=0&id=7ad220b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad220b6",
  null
  
)

export default component.exports