import { router } from './router/index.js';
import store from './vuex';
import { Message } from 'element-ui';
import { treeFlat } from '@/utils/tree.js';
import { getCookie } from '@/utils/util.js';

router.beforeEach(async (to, from, next) => {
    let { userInfo } = store.state;
    if (getCookie('AuthorizationBe')) {
        // 设置动态路由
        await store.dispatch('addRoute');
        let { routerList } = userInfo;
        if (!to.name) {
            let routerListFlat = [];
            treeFlat(routerList, 'children', item => {
                routerListFlat.push(item);
            });
            if (routerListFlat.findIndex(i => i.path === to.path) !== -1) {
                next({ ...to, replace: true });
            } else {
                next('/404');
            }
        } else {
            next();
        }
    } else {
        console.log('to.path ==-----', to.path === '/archives/detail');
        if (
            to.path === '/login' ||
            to.path === '/jump-sczshome' ||
            to.path === '/jump-scj' ||
            to.path === '/archives/detail'
        ) {
            next();
        } else {
            Message.error('请先登录');
            next('/login');
        }
    }
});
