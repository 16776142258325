import rq from '@/utils/https.js';
const $ajax = rq.requests;
import { setCookid, getCookie, waterFall } from '@/utils/util.js';

export const loginPort = data =>
    $ajax({
        data,
        url: '/login/auth',
        method: 'post',
    });
export const getUserInfo = data =>
    $ajax({
        data,
        url: '/login/user/info',
        method: 'get',
    });

export const getLogout = data =>
    $ajax({
        data,
        url: '/login/logout',
        method: 'get',
    });

//获取菜单
export const getMenu = params =>
    $ajax({
        params,
        url: '/login/user/perm',
        method: 'get',
    });
// 获取上传oss token
export const getOssConfig = params =>
    $ajax({
        url: '/user/v2.4/GetOssToken', //dpb
        // url: '/scm/v1/ProductPlatform/GetOssToken',//dpwcc
        method: 'get',
        params,
    });

export default {};
