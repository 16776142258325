<template>
    <div class="accountMain">
        <el-form label-width="120px">
            <el-form-item label="姓名">
                <div>{{ userinfo.username }}</div>
            </el-form-item>
            <el-form-item label="尚层账号">
                <div>{{ $store.state.login.username }}</div>
            </el-form-item>
            <el-form-item label="描述">
                <div>{{ userinfo.description }}</div>
            </el-form-item>
            <!-- <el-form-item label="头像">
                <el-upload
                    class="avatar-uploader"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :headers="uploadHeaders"
                    :data="uploadData"
                    accept=".png,.jpg,.jpeg"
                >
                    <img v-if="userinfo.avatarPath" :src="userinfo.avatarPath" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <div class="huanimg">更换头像</div>
                    <div class="el-upload__tip" slot="tip">
                        支持png,jpg,jpeg格式，最多1张，单个图片不超过1MB
                    </div>
                </el-upload>
            </el-form-item> -->

            <!-- <el-form-item label="密码">
                <el-button type="text" @click="changemi">修改密码</el-button>
            </el-form-item> -->
        </el-form>
        <!-- 修改密码 -->
        <!-- <el-dialog
            title="修改密码"
            :visible.sync="dialogFormurl"
            width="40%"
            :close-on-click-modal="false"
        >
            <el-form class="zidinurl" :model="form1" ref="form1" :rules="rules" label-width="120px">
                <el-form-item label="请输入旧密码" prop="oldPassword">
                    <el-input
                        v-model="form1.oldPassword"
                        size="mini"
                        show-password
                        placeholder="请输入旧密码"
                        @keyup.native="keyupFn('oldPassword')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="请输入新密码" prop="newPassword">
                    <el-input
                        v-model="form1.newPassword"
                        size="mini"
                        show-password
                        placeholder="请输入新密码"
                        @keyup.native="keyupFn('newPassword')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="重复新密码" prop="newPassword1">
                    <el-input
                        v-model="form1.newPassword1"
                        size="mini"
                        show-password
                        placeholder="重复新密码"
                        @keyup.native="keyupFn('newPassword1')"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormurl = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="submitmi('form1')" size="mini">保 存</el-button>
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import { getCookie, setCookid } from '@/utils/util.js';
// import { updatePassword } from '@/api/login.js';
export default {
    name: 'aunt',
    data() {
        return {
            form1: {
                oldPassword: '',
                newPassword: '',
                newPassword1: '',
            },
            dialogFormurl: false,
            uploadUrl: 'butler-system-web/upload/saveFile',
            rules: {
                oldPassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
                ],
                newPassword1: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
                ],
            },
            uploadData: {
                fileDir: 'userinfo',
            },
            uploadHeaders: {
                Authorization: getCookie('AuthorizationBe') || '',
            },
        };
    },
    computed: {
        userinfo() {
            return this.$store.state.userInfo;
        },
    },
    methods: {
        // 修改密码
        changemi() {
            this.dialogFormurl = true;
            this.form1.oldPassword = '';
            this.form1.newPassword = '';
            this.form1.newPassword1 = '';
        },
        submitmi(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.form1.newPassword != this.form1.newPassword1) {
                        this.$message.error('两次输入密码不一致');
                        this.form1.newPassword = '';
                        this.form1.newPassword1 = '';
                    } else {
                        console.log(this.form1);
                        let data = { ...this.form1 };
                        // updatePassword({
                        //     data,
                        // })
                        //     .then(res => {
                        //         if (res.code === 0) {
                        //             this.$message.success('修改成功');
                        //             this.dialogFormurl = false;
                        //             setCookid('AuthorizationBe', '', 0.001);
                        //             this.$store.dispatch('logout');
                        //             this.$router.push({ path: '/login' });
                        //         }
                        //     })
                        //     .catch(err => {
                        //         this.$message.error(err.msg);
                        //     });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // handleAvatarSuccess(res, file) {
        //     this.userinfo.avatarPath = res.data.webUrl;
        //     updateImg({
        //         avatarPath: res.data.webUrl,
        //     })
        //         .then(res => {
        //             if (res.code === 0) {
        //                 this.$message.success('修改成功');
        //             }
        //         })
        //         .catch(err => {
        //             this.$message.error(err.msg);
        //         });
        // },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const is1M = file.size / 1024 > 1024 ? false : true;
            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!is1M) {
                this.$message.error('上传头像图片大小不能超过 1MB!');
            }
            return is1M;
        },
        keyupFn(key) {
            this.form1[key] = this.form1[key].replace(/[\u4e00-\u9fa5]/g, '');
        },
    },
};
</script>

<style lang="less" scoped>
.accountMain {
    width: 98%;
    margin: 10px auto;
    padding: 10px 20px;
    background: #fff;
    .avatar-uploader {
        height: 122px;
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409eff;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 120px;
            height: 120px;
            line-height: 120px;
            text-align: center;
            border: 1px;
        }
        .avatar {
            width: 120px;
            height: 120px;
            display: block;
        }
        .huanimg {
            width: 120px;
            height: 24px;
            line-height: 24px;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .el-upload__tip {
            position: absolute;
            bottom: -10px;
            margin-top: 0px;
            height: 16px;
            color: #999;
        }
    }
    .zidinurl {
        .el-input {
            width: 200px;
        }
    }
}
</style>
