<template>
    <div class="frame__head">
        <a href="javaScript:void(0);" class="logo" :class="{ 'menu-fold': !open }">
            <img src="../assets/images/title.png" class="logo_img" />
            <div>尚层家管理系统</div>
        </a>
        <div class="breadcrumb-wrap">
            <breadcrumb></breadcrumb>
        </div>
        <div class="menu_box">
            <el-dropdown @command="command">
                <span class="el-dropdown-link">
                    <i
                        v-if="userinfo.avatarPath"
                        class="user-icon"
                        :style="{
                            backgroundImage: 'url(' + userinfo.avatarPath + ')',
                            backgroundSize: 'contain',
                        }"
                    ></i>
                    <i class="user-icon" v-else></i>
                    {{ userinfo.username }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="/userinfo/account">个人中心</el-dropdown-item>
                    <el-dropdown-item @click="logout" command="/login" divided>
                        退出登录
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { setCookid } from '@/utils/util.js';
import { getLogout } from '@/api/login.js';
import breadcrumb from '@/components/breadcrumb';
export default {
    name: 'IframeHead',
    components: { breadcrumb },
    props: {
        open: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        userinfo() {
            return this.$store.state.userInfo;
        },
    },
    methods: {
        command(url) {
            if (url == '/login') {
                this.logout();
            } else {
                this.$router.push({ path: url });
            }
        },
        logout() {
            this.loading = true;
            getLogout({
                params: {},
            })
                .then(res => {
                    console.log(res);
                    if (res.code === 200) {
                        this.loading = false;
                        this.$message(res.message);
                        setCookid('AuthorizationBe', '', 0.001);
                        this.$store.dispatch('logout');
                        this.$router.push({ path: '/login' });
                    }
                })
                .catch(err => {
                    this.$message.error(err.message);
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.breadcrumb-wrap {
    flex: 1;
    padding: 4px 26px 0;
}
.el-dropdown-link {
    position: relative;
}
.user-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxjaXJjbGUgaWQ9ImEiIGN4PSIyMyIgY3k9IjIzIiByPSIyMyIvPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iIzAwNkVGRiIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPjxwYXRoIGQ9Ik0zOC43NTQgMzkuNzU4QTIyLjkzNiAyMi45MzYgMCAwMDQ2IDIzQzQ2IDEwLjI5NyAzNS43MDMgMCAyMyAwUzAgMTAuMjk3IDAgMjNhMjIuOTM2IDIyLjkzNiAwIDAwNy4yNiAxNi43NzJjNC4xMTUgMy44NjIgMjcuMzc4IDMuODU2IDMxLjQ5NC0uMDE0eiIgZmlsbD0iIzAwNkVGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBtYXNrPSJ1cmwoI2IpIi8+PHBhdGggZD0iTTQwLjU3MyA0NmMtLjM5NS0yLjA0OC0xLjA3OC00Ljk5NS0xLjc2LTYuMTEtMS4wNzktMS43OTYtMy41OTQtMi44NzQtNS43NS00LjMxMi0yLjE1Ny0xLjQzNy01Ljc1LTMuMjM0LTUuNzUtNS4zOSAwLTEuNzk3IDEuNzk2LTIuNTE2IDIuODc1LTUuMDMyIDEuMDA2LTIuMzcyIDEuMDc4LTQuNjcyIDEuMDc4LTguMjY1IDAtMy45NTMtMy41OTQtNy4xODgtOC4yNjYtNy4xODgtNC42NzIgMC04LjI2NiAzLjIzNS04LjI2NiA3LjE4OCAwIDMuNTkzLjA3MiA1Ljg5MyAxLjA3OSA4LjI2NSAxLjA3OCAyLjUxNiAyLjg3NCAzLjIzNSAyLjg3NCA1LjAzMiAwIDIuMTU2LTMuNTkzIDMuOTUzLTUuNzUgNS4zOS0yLjE1NiAxLjQzOC00LjY3MSAyLjUxNi01Ljc1IDQuMzEzLS42ODIgMS4xMTQtMS4zMyA0LjA2LTEuNzYgNi4xMDloMzUuMTQ2eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBtYXNrPSJ1cmwoI2IpIi8+PC9nPjwvc3ZnPg==);
    background-size: contain;
    position: absolute;
    left: -30px;
    top: -3px;
}
.el-icon-arrow-down {
    font-size: 12px;
}
</style>
