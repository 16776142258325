import rq from '@/utils/https.js';
const $ajax = rq.requests;

// // /article/page
export const articlePage = data =>
    $ajax({
        url: '/article/page',
        method: 'post',
        data,
    });
export const info = params =>
    $ajax({
        url: '/article/info',
        method: 'get',
        params,
    });
export const approval = data =>
    $ajax({
        url: '/article/approval',
        method: 'put',
        data,
    });
export const worksEdit = data =>
    $ajax({
        url: '/article/approval/update',
        method: 'put',
        data,
    });

export const manualReview = params =>
    $ajax({
        url: '/dict/manual-review',
        method: 'get',
        params,
    });

export const assArticlePage = data =>
    $ajax({
        url: '/topic/article/page',
        method: 'post',
        data,
    });

export const association = data =>
    $ajax({
        url: '/topic/association',
        method: 'post',
        data,
    });

export const manualReviewEcho = params =>
    $ajax({
        url: '/dict/manual-review-echo',
        method: 'get',
        params,
    });
export const getNoteTypeList = params =>
    $ajax({
        url: '/dict/v2.2/getNoteTypeList/',
        method: 'get',
        params,
    });
// 笔记专题列表
export const getNoteTypeListOption = params =>
    $ajax({
        url: '/article/note/type/list',
        method: 'get',
        params,
    });
// 添加笔记
// 1.当isDraft=0时，表示发布信息;
// 2.当isDraft=1时，表示存入草稿箱;
// 3.当articleId=null时，表示新增文章，反之为修改;
// **注意：发布时title（文章标题）、files（文件集合）必传
export const addArticle = data =>
    $ajax({
        url: '/article/save/update',
        method: 'post',
        data,
    });
// 话题筛选项列表
export const getTopicOptions = params =>
    $ajax({
        url: '/topic/congruence/list',
        method: 'get',
        params,
    });
// 作者列表
export const getDesignerList = params =>
    $ajax({
        url: '/user/internal/list',
        method: 'get',
        params,
    });
export const getDesignerListOnly = params =>
    $ajax({
        url: '/user/getDesignerList',
        method: 'get',
        params,
    });
