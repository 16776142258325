<template>
    <!-- 浏览记录 -->
    <div class="right-wrap">
        <div class="right-title" v-if="id">记录详情</div>
        <div>
            <el-tabs v-model="active" @tab-click="handleClick">
                <el-tab-pane
                    :label="item.cateName"
                    :key="item.cateId"
                    :name="item.cateId"
                    v-for="item in info"
                ></el-tab-pane>
            </el-tabs>
            <div class="question-wrap">
                <div
                    class="question-item"
                    v-for="(item, index) in infoData[active]?.answerVoList"
                    :key="index"
                >
                    <div class="ques-title">
                        <span>{{ index + 1 + '、' }}</span>
                        <span>{{ item.questions }}</span>
                        <!-- <span>
                            {{
                                item.questionType &&
                                    '（' + questionTypeObj[item.questionType] + '）'
                            }}
                        </span> -->
                    </div>
                    <div
                        class="ques-value"
                        v-if="item.questionType !== 'image' && !item?.optionName?.includes('http')"
                    >
                        {{ item.optionName }}
                    </div>
                    <div class="ques-image" v-else>
                        <el-image
                            class="img"
                            v-for="(image, imageIndex) in item.optionImages"
                            :key="imageIndex"
                            :src="image"
                            fit="contain"
                            :preview-src-list="item.optionImages"
                        />
                    </div>
                </div>
            </div>
        </div>
        <el-empty v-if="!info.length && id" description="暂无数据"></el-empty>

        <!-- <div>{{ infoData }}</div> -->
    </div>
</template>
<script>
import {
    getUserQuestionList,
    getUserQuestionResultDetailNew,
    getUserQuestionResultDetailById,
} from '@/api/mkt/user.js';

const platformObj = {
    1: 'BPM',
    2: '尚层之家',
    3: '尚层家',
};
const questionTypeObj = {
    redio: '单选',
    radio: '单选',
    checkbox: '多选',
    input: '填空',
    pickerGroup: '滚轮',
    image: '图片收集',
};

export default {
    name: 'browse-list',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tabCurrent: '11',
            tabList: [
                { label: '居住者信息', id: '11', num: '' },
                { label: '房屋信息', id: '22', num: '' },
                { label: '设计需求', id: '33', num: '' },
                { label: '大类', id: '44', num: '' },
            ],
            active: '',
            info: [],
            infoData: {},
            questionTypeObj,
        };
    },

    watch: {
        id: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.getUserQuestionResultDetailById(newVal);
                } else {
                    this.getUserQuestionResult();
                }
            },
        },
    },
    mounted() {},
    methods: {
        async getUserQuestionResult() {
            let params = {
                accountId: this.$route.query.accountId,
            };
            let { isSuccess, data } = await getUserQuestionResultDetailNew(params);
            if (isSuccess) {
                this.setData(data);
            }
        },
        async getUserQuestionResultDetailById(val) {
            let params = {
                resultId: val,
            };
            let { isSuccess, data } = await getUserQuestionResultDetailById(params);
            if (isSuccess) {
                this.setData(data);
            }
        },
        setData(data) {
            data.forEach(item => {
                item.answerVoList &&
                    item.answerVoList.forEach(element => {
                        if (
                            element.questionType == 'image' ||
                            element.optionName?.includes('http')
                        ) {
                            let name = element.optionName || '';
                            // name.replace(/[|]/g, ',');
                            element.optionImages = name.split('|');
                        }
                        // console.log(
                        //     '----------------element.optionImages',
                        //     element.questionType == 'image',
                        //     element.optionImages
                        // );
                    });
                this.infoData[item.cateId] = item;
            });
            this.active = (data && data[0] && data[0].cateId) || '';
            console.log('--------------data', this.active, this.infoData);
            this.info = data;
            !this.props?.id && data.total && this.$emit('setTabNum', { type: 2, num: data.total });
        },
        handleClick(tab) {
            this.active = tab.name;
        },
    },
};
</script>

<style lang="less" scoped>
.right-wrap {
    background: #fff;
    height: 100%;
    margin-top: 10px;
    .right-title {
        font-size: 20px;
        // position: absolute;
        // top: 10px;
        // padding-top: 10px;
        margin-top: -10px;
    }
    .question-wrap {
        padding: 0 20px 20px;
        font-weight: 600;
        .ques-title {
            padding: 16px 0 6px 0;
        }
        .ques-value {
            padding: 6px 0 6px 20px;
            border: 1px solid #c9c9c9;
        }
        .ques-image {
            display: flex;
            justify-content: left;
            .img {
                width: 144px;
                height: 154px;
                margin-right: 8px;
                object-fit: contain;
                background: #f3f3f3;
            }
        }
    }
}

.tabs {
    border-bottom: none;
    background-color: #fff;
    :deep(.el-tabs__header) {
        margin: 0;
    }
    :deep(.el-tabs__nav-scroll) {
        padding: 0 16px;
    }
    :deep(.el-tabs__nav-wrap::after) {
        height: 1px;
        background-color: #f3f4f6;
    }
}
</style>
