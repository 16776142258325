/**
 * tree处理
 * 2021-5-14  xbsimida@126.com
 */

/**
 * 扁平化
 * tree:数据(Array),
 * childKey:子集key(String)
 * func:回调(Obj)
 */
export const treeFlat = (tree,childKey,func) => {
    tree.forEach(item => {
        item[childKey] && treeFlat(item[childKey],childKey,func)
	func(item)
    })
};

/**
 * 查找节点路径
 * tree:数据(Array),
 * data:{
 *    childKey:子集key(String)
 *    pathKey:路径key(String)
 *    path:路径(Array)
 * }
 * func:回调(Obj)
 */
 export const treeFindPath = (tree,data,func) => {
    let childKey = data.childKey;
    let pathKey = data.pathKey;
    let path = data.path;
    if (!tree) return []
    for (const item of tree) {
        path.push(item[pathKey])
        if (func(item)) return path
        if (item[childKey]) {
            const findChildren = treeFindPath(item[childKey],{
                childKey:childKey,
                pathKey:pathKey,
                path:path,
            },func)
            if (findChildren.length) return findChildren
        }
        path.pop()
    }
    return []
};

/**
 * 找子节点
 * data:{
 *   tree:数据(Array),
 *   childKey:子集key(String)
 * }
 * func:回调(Obj)
 */
 export const treeFindChild = (tree,childKey,func) => {
    for (const item of tree) {
        if (func(item)) return item
        if (item[childKey]) {
            const res = treeFindChild(item[childKey],childKey,func)
            if (res) return res
        }
    }
    return null
};


export default {};