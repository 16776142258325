import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 获取标签组列表
export const getList = params =>
    $ajax({
        url: '/inspiration/list',
        method: 'get',
        params,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });

// 新增标签组
export const save = data =>
    $ajax({
        url: '/inspiration/save',
        method: 'post',
        data,
    });

// 灵感集案例图库
// /inspiration/map-depot
export const mapdepot = params =>
    $ajax({
        url: '/inspiration/map-depot',
        method: 'get',
        params,
    });
//灵感集详情    /inspiration/info/{id}
export const detailID = id =>
    $ajax({
        url: '/inspiration/info/' + id,
        method: 'get',
    });
//  /inspiration/update  修改灵感集
export const update = data =>
    $ajax({
        url: '/inspiration/update',
        method: 'post',
        data,
    });
// /inspiration/space-list 空间列表

export const spaceList = data =>
    $ajax({
        url: '/inspiration/space-list',
        method: 'get',
        data,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
export const coverList = data =>
    $ajax({
        url: '/user-designer-collection/ranking/works/cover-list',
        method: 'get',
        params: data,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 编辑标签组
export const editTag = data =>
    $ajax({
        url: '/design/labels-data/edit-label-library',
        method: 'post',
        data,
    });

// 编辑标签组状态
export const editTagState = data =>
    $ajax({
        url: '/design/labels-data/edit-label-library-state',
        method: 'post',
        data,
    });

// 删除
export const del = data =>
    $ajax({
        url: `/inspiration/del/${data.id}`,
        method: 'delete',
    });

// 获取适用栏目
export const getCloumn = params =>
    $ajax({
        url: '/dpapi/v1/LabelLibrary/GetColumnData',
        method: 'get',
        params,
    });
// /content-collection/ranking/works/cover-list
export const getCoverList = params =>
    $ajax({
        url: '/content-collection/ranking/works/cover-list',
        method: 'get',
        params,
    });
export default {};
