<template>
    <div id="app">
        <iframe-head :open="menu" />
        <div id="product-navbar" class="product-main" :class="{ active: menu }">
            <iframe-left ref="iframeLeft" @menu="menuFn" />
            <div class="g-body">
                <transition name="move" mode="out-in" class="el-main">
                    <!-- <template v-if="path.indexOf('/order/') > -1">
                        <router-view></router-view>
                    </template>
                    <template v-else> -->
                    <!-- <keep-alive exclude="/serve/cls-add"> -->
                    <!-- <keep-alive
                        :exclude="[
                            'acls-add',
                            'product-add',
                            'quan-add',
                            'order-list',
                            'order-aunt-list',
                            'order-worker-list',
                            'order-need-list',
                            'order-auntback-list',
                            'order-auntback-list',
                        ]"
                    >
                        <router-view></router-view>
                    </keep-alive> -->
                    <!-- </template> -->
                    <router-view></router-view>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import IframeLeft from '../components/iframe-left.vue';
import IframeHead from '../components/iframe-head.vue';
import { loginPort, getMenu } from '@/api/login.js';
import { getCookie } from '@/utils/util.js';

export default {
    name: 'App',
    data() {
        return {
            menu: true,
            breadShow: true,
            path: '',
        };
    },
    watch: {
        $route: {
            handler: function(val, oldVal) {
                this.path = val.path;
            },
            immediate: true,
        },
    },
    components: {
        IframeLeft,
        IframeHead,
    },
    mounted() {
        // getMenu({ ModuleId: 'sc1650253079279', token: getCookie('AuthorizationBe') })
        //     .then(res => {
        //         if (res.isSuccess) {
        //         }
        //     })
        //     .catch(err => {
        //         this.$message.error(err.message);
        //     });
    },
    methods: {
        menuFn() {
            this.menu = !this.menu;
        },
    },
};
</script>

<style lang="less" scoped></style>
