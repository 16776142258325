import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 获取标签组列表
export const getTagList = params =>
    $ajax({
        url: `/labels/info`,
        method: 'get',
        params,
    });

// 新增标签组
export const addAndEdit = data =>
    $ajax({
        url: '/labels/edit',
        method: 'post',
        data,
    });
// /labels/labellist 标签组全部

export const labellist = params =>
    $ajax({
        url: '/labels/labellist',
        method: 'get',
        params,
    });
// /labels/getLabel/{labelGroupId}  根据标签组id获取所属标签
export const getChildList = id =>
    $ajax({
        url: `/labels/getLabel/${id}`,
        method: 'post',
    });

// 编辑标签组
export const editTag = data =>
    $ajax({
        url: '/design/labels-data/edit-label-library',
        method: 'post',
        data,
    });

// 标签ku状态
export const editLabelLibraryState = data =>
    $ajax({
        url: '/labels/editstate',
        method: 'put',
        data,
    });

// 删除标签ku
export const del = data =>
    $ajax({
        url: '/labels/del',
        method: 'delete',
        data,
    });
// /labels/operation/list 运营标签
export const operationList = params =>
    $ajax({
        url: '/labels/operation/list',
        method: 'get',
        params,
    });
// 获取适用栏目
export const getCloumn = params =>
    $ajax({
        url: '/dpapi/v1/LabelLibrary/GetColumnData',
        method: 'get',
        params,
    });

export default {};
