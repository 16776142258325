<template>
    <!-- 户型规划/装修预算 -->
    <div class="item-box">
        <div class="top-line">
            <div class="tit">户型规划/装修预算</div>
            <div class="right" v-if="active == '999'">
                <el-button type="text" @click="handleCheckTab()" size="mini">
                    查看更多
                </el-button>
            </div>
        </div>
        <div class="infor-item">
            <sc-table-v2
                v-if="active == '2' || data.length"
                :head="tableHead"
                :data="data"
                :page="page"
                :page-hide="pageHide"
                :read-table="true"
                :load="load"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :selection="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            ></sc-table-v2>
        </div>
    </div>
</template>
<script>
const appointmentTypeObj = {
    5: '户型规划',
    6: '装修预算',
};
export default {
    name: 'browse-list',
    props: {
        tabActive: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            active: '',
            load: false,
            pageHide: true,
            tableHead: [
                { name: '类型', key: 'appointmentType', formatter: this.appointmentTypeFormat },
                { name: '时间', key: 'createTime' },
                { name: '所在城市', key: 'city', formatter: this.cityFormat },
                { name: '小区名称', key: 'realEstate' },
                { name: '府邸面积(㎡)', key: 'area' },
                { name: '手机号码', key: 'mobile' },
            ],
            tableHeightCalc: null,
            page: {
                size: 4,
                page: 1,
                count: 0,
            },
            list: [],
        };
    },

    watch: {
        tabActive: {
            immediate: true,
            handler(newVal) {
                this.list = [];
                this.active = newVal;
                if (newVal == '999') {
                    this.tableHeightCalc = null;
                } else if (newVal == '5') {
                    this.tableHeightCalc = window.innerHeight - 500;
                }
            },
        },
    },
    methods: {
        // 切换tab
        handleCheckTab() {
            this.$parent.handleClick({ label: '户型规划/装修预算', name: '5' });
        },
        cityFormat(row) {
            let province = ['北京市', '上海市', '天津市', '重庆市'].includes(row.province)
                ? ''
                : row.province;
            return province + row.city + (row.region || '');
        },
        appointmentTypeFormat(row) {
            return appointmentTypeObj[row.appointmentType] || '';
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
        margin-top: 10px;
    }
}
</style>
