// 权限管理菜单
export default [
    {
        path: '/mkt/work',
        component: 'Admin',
        name: '工作台',
        showChild: true,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/work/todo',
                component: 'mkt/work/todo/index',
                name: '待办事项',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/work/search-data',
                component: 'mkt/work/search-data/index',
                name: '搜索数据报表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
        ],
    },
    {
        path: '/mkt/cm',
        component: 'Admin',
        name: '内容管理',
        showChild: true,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/cm/exhibit',
                component: 'mkt/cm/exhibit/index',
                name: '案例管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/exhibit/exhibit-add',
                component: 'mkt/cm/exhibit/exhibit-add',
                name: '新增案例',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/exhibitStatusList',
                component: 'mkt/cm/status/index',
                name: '案例切片',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/status/detail',
                component: 'mkt/cm/status/detail',
                name: '案例动态详情',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/article',
                component: 'mkt/cm/article/index',
                name: '笔记管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/article-img',
                component: 'mkt/cm/article-img/index',
                name: '长图文',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/article-img/detail',
                component: 'mkt/cm/article-img/detail',
                name: '新增长图文',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/contact-article',
                component: 'mkt/cm/contact-article',
                name: '关联笔记',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/exhibit-group',
                component: 'mkt/cm/exhibit-group/index',
                name: '灵感集管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/article-group',
                component: 'mkt/cm/article-group/index',
                name: '专题管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/cm/topic',
                component: 'mkt/cm/topic/index',
                name: '话题管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
        ],
    },
    {
        path: '/mkt/om',
        component: 'Admin',
        name: '运营管理',
        showChild: true,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/om/pages',
                component: 'mkt/om/pages/index',
                name: '页面装修',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            // {
            //     path: '/mkt/om/markets',
            //     component: 'mkt/om/markets/index',
            //     name: '运营位配置',
            //     showChild: false,
            //     children: null,
            //     meta: {
            //         showMenu: true,
            //         btns: null,
            //     },
            // },
            {
                path: '/mkt/om/market',
                component: 'mkt/om/market/index',
                name: '运营内容',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/home-group',
                component: 'mkt/om/home-group/index',
                name: '内容合集',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/content-group',
                component: 'mkt/om/content-group/index',
                name: '内容合集详情',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/designer-ranking',
                component: 'mkt/om/designer-ranking/index',
                name: '设计师榜详情',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/designer-ranking-quick',
                component: 'mkt/om/designer-ranking-quick/index',
                name: '设计师榜',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/recommend-banner',
                component: 'mkt/om/recommend-banner/index',
                name: '首页推荐banner',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/week-case',
                component: 'mkt/om/week-case/index',
                name: '每周精选案例',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/message',
                component: 'mkt/om/message/index',
                name: '消息配置',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/homeTab',
                component: 'mkt/set/homeTab/homeTab',
                name: '首页Tab',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/feed-sort',
                component: 'mkt/om/feed-sort/index',
                name: 'feed排序干预',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/search-discovery',
                component: 'mkt/om/search-discovery/index',
                name: '搜索发现',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/om/style-test',
                component: 'mkt/om/style-test/index',
                name: '风格测试',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
        ],
    },
    {
        path: '/mkt/set',
        component: 'Admin',
        name: '基础配置',
        showChild: false,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/set/homeTab',
                component: 'mkt/set/homeTab/homeTab',
                name: '首页Tab',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/checkbox',
                component: 'mkt/set/checkbox/index',
                name: '筛选管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/checkbox',
                component: 'mkt/set/checkbox/add',
                name: '添加筛选',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/category',
                component: 'mkt/set/category/index',
                name: '分类',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/category/add',
                component: 'mkt/set/category/add',
                name: '添加分类',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/tag',
                component: 'mkt/set/tag/index',
                name: '标签',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/tag/add',
                component: 'mkt/set/tag/add',
                name: '新增标签',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/tag/tag-group',
                component: 'mkt/set/tag-group/index',
                name: '标签组',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
            {
                path: '/mkt/set/tag-group/add',
                component: 'mkt/set/tag-group/add',
                name: '新增标签组',
                showChild: false,
                children: null,
                meta: {
                    showMenu: false,
                    btns: null,
                },
            },
        ],
    },
    {
        path: '/mkt/user',
        component: 'Admin',
        name: '用户管理',
        showChild: true,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/user/staff',
                component: 'mkt/user/staff/index',
                name: '员工列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/potental-cus',
                component: 'mkt/user/potental-cus/index',
                name: '潜客列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/coupon-cus',
                component: 'mkt/user/coupon-cus/index',
                name: '获客来源统计',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/list',
                component: 'mkt/user/list/index',
                name: '用户列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/list',
                component: 'mkt/user/list/index',
                name: '用户列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/archives',
                component: 'mkt/user/archives/index',
                name: '用户档案统计',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },

            {
                path: '/mkt/user/archives/detail',
                component: 'mkt/user/archives/detail',
                name: '用户档案统计详情',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/designer',
                component: 'mkt/user/designer/index',
                name: '设计师列表干预',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/user/code-list',
                component: 'mkt/user/code-list/index',
                name: '用户验证码列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
        ],
    },
    {
        path: '/mkt/mkt-management',
        component: 'Admin',
        name: '营销管理',
        showChild: true,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/mkt-management/index',
                component: 'mkt/mkt-management/index',
                name: '线索获客',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/mkt-management/adver/list',
                component: 'mkt/mkt-management/adver/list',
                name: '广告管理',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/mkt-management/gaisuan/list',
                component: 'mkt/mkt-management/gaisuan/list',
                name: '装修报价',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
        ],
    },
    {
        path: '/mkt/acquisition',
        component: 'Admin',
        name: '分享获客',
        showChild: true,
        icon: 'el-icon-school',
        children: [
            {
                path: '/mkt/acquisition/acqu-staff',
                component: 'mkt/acquisition/acqu-staff/index',
                name: '员工列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/acquisition/acqu-customer',
                component: 'mkt/acquisition/acqu-customer/index',
                name: '客户列表',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
            {
                path: '/mkt/acquisition/share-customer',
                component: 'mkt/acquisition/share-customer/index',
                name: '分享动作明细',
                showChild: false,
                children: null,
                meta: {
                    showMenu: true,
                    btns: null,
                },
            },
        ],
    },
];
