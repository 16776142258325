import { getSyscategoryList as getData } from '@/api/mkt/category.js';
export default {
    state: {
        params: {
            categorysName: '',
        },
        list: [],
        isHasSearch: false,
    },
    mutations: {
        setParams(state, data) {
            state.params = data;
        },
        setList(state, data) {
            state.list = data;
        },
        setHas(state, data) {
            state.isHasSearch = data;
        },
        clear(state) {
            state.list = [];
        },
    },
    actions: {
        list({ commit }) {
            if (!getData) {
                return false; //
            }
            return new Promise(resolve => {
                getData(this.state['mkt-category'].params).then(res => {
                    if (res?.isSuccess) {
                        let data = res.data.children || [];
                        for (let item of data) {
                            item.isTop = true;
                        }
                        commit('setList', data);
                        resolve(data);
                    }
                });
            });
        },
    },
};
