import {} from '@/api/mkt/article-group.js';

export default {
    state: {
        labelList: [],
        categoryList: [],
        companyList: [],
    },
    mutations: {
        setLabelList(state, data) {
            state.labelList = data;
        },
        setCategoryList(state, data) {
            state.categoryList = data;
        },

        setCompanyList(state, data) {
            state.companyList = data;
        },
    },
    actions: {
        // parseList({ commit }) {
        //     let tempData = [];
        //     let _list = this.state['mkt-article-group'].list;
        //     _list.forEach(item => {
        //         let rowObj = {};
        //         for (let i in item) {
        //             if (!Array.isArray(item)) {
        //                 let attrObj = {
        //                     [i]: {
        //                         type: 'text',
        //                         options: {
        //                             defaultValue: item[i],
        //                         },
        //                     },
        //                 };
        //                 Object.assign(rowObj, attrObj);
        //             }
        //         }
        //         tempData.push(rowObj);
        //     });
        //     console.log(tempData, 'tempData');
        //     commit('setParseList', tempData);
        // },
    },
    getters: {
        showNum(state) {
            console.log(state, 'state');
        },
    },
};
