<template>
    <div class="mkt" ref="table">
        22222222222222
        <div id="imageContainer"></div>
    </div>
</template>

<script>
import rq from '@/utils/https.js';
const $ajax = rq.requests;
export default {
    name: 'staff-index',
    data() {
        return {};
    },
    created() {
        this.createImg();
    },

    methods: {
        async createImg() {
            let data = {
                page: 'pages/tabbar/case/index',
                scene: 'origin=offiaccount',
                check_path: true,
                env_version: 'release', //develop  正式release
                width: 1280, //最小 280px，最大 1280px
                auto_color: true,
                is_hyaline: true,
            };
            let res = await $ajax({
                url:
                    '/wxa/getwxacodeunlimit?access_token=79_1qQnjPoKMOXTMglqLh4pkZ2zBBPEyPNic-w-UIvsTgVUGvVpjPqIltaa0OBd3OkoRqy7FSwuJ8IpCkAJWVVLtXRvBG3Sx9cE0yr-6EkS6phRgHEG7LWvAB0EIScDQCgADATGS',
                method: 'post',
                data,
                responseType: 'blob',
            });
            console.log('-----------------------res', res);
            // 创建一个新的Image对象
            var img = new Image();
            // 定义加载完成后的处理函数
            img.onload = function() {
                // 将图像添加到HTML文档中的某个元素内部
                document.getElementById('imageContainer').appendChild(img);
            };
            window.URL = window.URL || window.webkitURL;
            // 1 图片地址使用二进制数据展示；不可下载
            // var blobUrl = URL.createObjectURL(res);
            // img.src=blobUrl
            // 2 图片二进制数据转base64展示；可以下载
            this.getBase64(res).then(base64 => {
                img.src = base64;
            });
        },
        getBase64(data) {
            return new Promise((resolve, reject) => {
                const blob = new Blob([data], { type: 'image/png' }); // 必须指定type类型
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },
};
</script>
<style lang="less" scoped>
.tag-container {
    background: #fff;
}
.flex {
    display: flex;
    align-items: center;
}
</style>
