import { getCheckboxList as getData } from '@/api/mkt/checkbox.js';
export default {
    state: {
        params: {
            id: '',
            title: '',
            user: '',
        },
        list: [],
    },
    mutations: {
        setParams(state, data) {
            state.params = data;
        },
        setList(state, data) {
            state.list = data;
        },
        clear(state) {
            state.list = [];
        },
    },
    actions: {
        list({ commit }) {
            if (!getData) {
                return false; //
            }
            return new Promise(resolve => {
                getData(this.state.params).then(res => {
                    if (res.code === 200) {
                        let data = res.data || [];
                        if (data.length > 0) {
                            commit('setList', data);
                            resolve(data);
                        }
                    }
                });
            });
        },
    },
};
