<template>
    <div class="page__login">
        <div class="logo"></div>
        <div class="login_body">
            <div class="form">
                <div class="title">尚层家管理系统</div>
                <ul>
                    <li>
                        <label for="username"><i class="el-icon-user"></i></label>
                        <input
                            type="text"
                            name="username"
                            v-model="username"
                            placeholder="请输入BPM账号"
                        />
                    </li>
                    <li>
                        <label for="password"><i class="el-icon-lock"></i></label>
                        <input
                            type="password"
                            name="password"
                            v-model="password"
                            placeholder="请输入密码"
                            @keyup.enter="loginFn"
                        />
                    </li>
                    <li style="border: none">
                        <el-checkbox
                            v-model="$store.state.checked"
                            size="mini"
                            @change="setChecked"
                        >
                            记住密码
                        </el-checkbox>
                    </li>
                </ul>
                <el-button
                    type="primary"
                    class="btn_login"
                    size="small"
                    @click="loginFn"
                    :loading="loading"
                >
                    登录
                </el-button>
            </div>
            <div v-show="false" class="box" ref="box" id="box">
                <div class="item" ref="item">
                    <img
                        src="https://dm.voglassdc.com/oss/a067086a6c99825ee76c65e19842faab?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/2ff8e11541d6e44bfb2cc120c6228d44?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/2ff8e11541d6e44bfb2cc120c6228d44?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/2ff8e11541d6e44bfb2cc120c6228d44?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/2ff8e11541d6e44bfb2cc120c6228d44?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/80ee74a5929f773043f5a0789f9e6e34?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/80ee74a5929f773043f5a0789f9e6e34?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
                <div class="item">
                    <img
                        src="https://dm.voglassdc.com/oss/80ee74a5929f773043f5a0789f9e6e34?x-oss-process=image/resize,w_268"
                        alt=""
                    />
                </div>
            </div>
        </div>
        <div class="copy">Copyright © 尚层装饰（北京）有限公司</div>
    </div>
</template>

<script>
import '../assets/css/login.less';
// import routerList from '@/router/test.js';
import { setCookid, getCookie } from '@/utils/util.js';
import { loginPort, getMenu, getUserInfo } from '@/api/login.js';
// 引入
import { Base64 } from 'js-base64';
// console.log(Base64);
export default {
    name: 'App',
    data() {
        return {
            loading: false,
            username: '',
            password: '',
            checked: true,
        };
    },
    watch: {
        '$store.state.login.password': {
            handler(val) {
                if (!val) {
                    this.password = '';
                }
            },
            immediate: true,
        },
    },
    mounted() {
        let login = this.$store.state.login;
        if (login.username) {
            this.username = login.username;
            this.password = Base64.decode(login.password);
        }
    },
    methods: {
        async loginFn() {
            if (!this.username) {
                this.$message.error('请输入用户名');
                return;
            }
            if (!this.password) {
                this.$message.error('请输入密码');
                return;
            }
            let _userName = this.username.toUpperCase();
            setCookid('account', _userName, 1);
            await this.loginPort();
            let userAllInfo = await this.getUserInfo();
            let routerList = await this.getMenu();
            console.log(routerList, 'routerList');
            const userInfo = {
                username: userAllInfo.data.displayName,
                routerList: routerList,
            };
            this.$store.dispatch('login', userInfo).then(() => {
                if (!routerList || routerList.length == 0) {
                    this.$message.error('您暂无登录权限');
                } else {
                    let path =
                        (
                            (routerList[0] &&
                                routerList[0].children &&
                                routerList[0].children[0]) ||
                            routerList[0] ||
                            {}
                        ).path || '/category/category-manage';
                    this.$router.push({ path: path });
                }
            });
        },
        loginPort() {
            this.loading = true;
            return new Promise(resolve => {
                loginPort({
                    userAccount: this.username,
                    userPassword: this.password,
                    // type: 2,
                })
                    .then(res => {
                        this.loading = false;
                        if (res.code == 200) {
                            console.log('-------loginPort', res);
                            setCookid('AuthorizationBe', res.data.token, 1);
                            this.savePasword();

                            resolve(res);
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            });
        },
        getUserInfo() {
            this.loading = true;
            return new Promise(resolve => {
                getUserInfo({
                    token: getCookie('AuthorizationBe'),
                })
                    .then(res => {
                        this.loading = false;
                        if (res.code == 200) {
                            console.log(res, 'res');
                            this.savePasword(res.data);

                            resolve(res);
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            });
        },
        getMenu() {
            this.loading = true;
            return new Promise(resolve => {
                getMenu({ token: getCookie('AuthorizationBe') })
                    .then(res => {
                        this.loading = false;
                        if (res.code == 200) {
                            let routerList = [];
                            if (res?.data && res?.data[0]?.menuList) {
                                routerList = res.data[0].menuList.filter(d => d.path != '/make');
                            }
                            if (routerList.length) {
                                for (let router of routerList) {
                                    if (router.children && router.children.length) {
                                        router.children = router.children.filter(
                                            d => d.path && d.component && d.name
                                        );
                                    }
                                }
                                routerList = routerList.filter(
                                    d => d.path && d.component && d.name
                                );
                            }

                            resolve(routerList);
                        } else {
                            let result = [];
                            resolve(result);
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$message.error(err.message);
                    });
            });
        },
        // setMenu() {
        //     this.loading = true;
        //     getMenu({
        //         params: {},
        //     })
        //         .then(res => {
        //             console.log(res);
        //             if (res.code === 0) {
        //                 const userInfo = {
        //                     username: this.username,
        //                     routerList: res.data,
        //                 };
        //                 let path = res.data[0].children[0].path || '/userinfo/account';
        //                 this.$store.dispatch('login', userInfo).then(() => {
        //                     this.$router.push({ path: path });
        //                 });
        //                 this.getUserinfo(res.data);
        //                 this.loading = false;
        //             }
        //         })
        //         .catch(err => {
        //             this.$message.error(err.msg);
        //             this.loading = false;
        //         });
        // },
        // 记住密码
        setChecked() {
            this.$store.commit('setChecked', this.$store.state.checked);
        },
        savePasword(obj) {
            let login = {
                username: '',
                password: '',
                ...obj,
            };
            if (this.$store.state.checked) {
                login.username = this.username;
                // login.password = this.password;
                login.password = Base64.encode(this.password);
            } else {
                login.username = this.username;
                login.password = '';
            }
            this.$store.commit('setLogin', login);
        },
    },
};
</script>

<style lang="less" scoped>
/**瀑布流demo样式 */

img {
    width: 100%;
    height: 100%;
}

.box {
    margin: 40px auto;
    width: 100%;
    position: relative;
    box-sizing: content-box;
}
.item {
    position: absolute;
    border: 1px solid #5592e5;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
        transform: scale(1.05, 1.05);
    }
}
</style>
