import Vue from 'vue';
// 设置cookid
export const setCookid = (name, value, days) => {
    var Days = days || 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString();
};

// 获取cookid
export const getCookie = cname => {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return '';
};
// f防止二次点击
export const preventReClick = Vue.directive('preventReClick', {
    inserted: function(el, binding) {
        console.log(el.disabled);
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true;
                setTimeout(() => {
                    el.disabled = false;
                }, binding.value || 2000);
                //binding.value可以自行设置。如果设置了则跟着设置的时间走
                //例如：v-preventReClick='500'
            }
        });
    },
});
// 防抖
export function debounce(func, wait = 300) {
    let timeout = null;

    return function(...args) {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}
// 定义限流函数
export const throttleFn = (fn, delay = 3000) => {
    let lastCall = 0;
    return function(...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return fn(...args);
    };
};
// 数组去重
export const arr_duplicate = arr => {
    let res = new Map();
    arr.forEach(item => {
        item.sort((a, b) => a - b);
        res.set(item.join(), item);
    });
    return Array.from(res.values);
};

export const listToTree = list => {
    let info = list.reduce((map, node) => ((map[node.id] = node), (node.children = []), map), {});
    return list.filter(node => {
        info[node.pid] && info[node.pid].children.push(node);
        return !node.pid;
    });
};

// 路由懒加载
export const loadView = view => {
    return resolve => require([`@/page/${view}`], resolve);
};

// 权限路由异步添加组件
export const filterAsyncRouter = routeList => {
    return routeList.filter(route => {
        if (route.component) {
            route.component = loadView(route.component);
            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children);
            }
            return true;
        }
    });
};

/**
 * 获取浏览器url参数
 */
export const getQueryString = name => {
    var href = decodeURIComponent(location.href);
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = '';
    if (href.split('?').length < 2) {
        return false;
    }
    try {
        r = href.split('?')[1].match(reg);
    } catch (err) {}
    if (r != null && r != undefined && r != 'undefined') {
        return unescape(r[2]) || false;
    }
    return false;
};

// 下载文件
export const downloadExcel = (res, fileName) => {
    let blob = res;
    const reader = new FileReader();

    reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href

    reader.onload = e => {
        // 转换完成，创建一个a标签用于下载

        let a = document.createElement('a');
        a.style.display = 'none';
        a.download = fileName + '.xlsx';

        a.href = e.target.result;
        document.body.appendChild(a); // 修复firefox中无法触发click
        a.click();
        document.body.removeChild(a);
    };
};

export const formatTimeTwo = (number, format) => {
    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];
    // var date = new Date(number * 1000);//时间戳为10位
    var date = new Date(number); //时间戳为13位
    returnArr.push(date.getFullYear());
    returnArr.push(formatNumber(date.getMonth() + 1));
    returnArr.push(formatNumber(date.getDate()));

    returnArr.push(formatNumber(date.getHours()));
    returnArr.push(formatNumber(date.getMinutes()));
    returnArr.push(formatNumber(date.getSeconds()));

    for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
};
export const formatDay = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${[year, month, day].map(formatNumber).join('-')}`;
};
const formatNumber = n => {
    n = n.toString();
    return n[1] ? n : `0${n}`;
};
export function deepClone(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    if (obj instanceof Array) {
        var copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepClone(obj[i]);
        }
        return copy;
    }
    if (obj instanceof Object) {
        var copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = deepClone(obj[attr]);
            }
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
}

// export function formatTimeTwo(number, format) {
//     var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
//     var returnArr = [];
//     // var date = new Date(number * 1000);//时间戳为10位
//     var date = new Date(number); //时间戳为13位
//     returnArr.push(date.getFullYear());
//     returnArr.push(formatNumber(date.getMonth() + 1));
//     returnArr.push(formatNumber(date.getDate()));

//     returnArr.push(formatNumber(date.getHours()));
//     returnArr.push(formatNumber(date.getMinutes()));
//     returnArr.push(formatNumber(date.getSeconds()));

//     for (var i in returnArr) {
//         format = format.replace(formateArr[i], returnArr[i]);
//     }
//     return format;
// }
// 获取随机数(时间戳+四位随机数)
export function getRandomNumber() {
    var num = ''; //定义用户编号
    for (
        var i = 0;
        i < 4;
        i++ //4位随机数，用以加在时间戳后面。
    ) {
        num += Math.floor(Math.random() * 10);
    }
    num = new Date().getTime() + num; //时间戳，用来生成用户编号。
    return num;
}

export default {};
