import rq from '@/utils/https.js';
const ajax = rq.requests;

// 获取筛选列表
export const getCheckboxList = params =>
    ajax({
        url: '/configuration/list',
        method: 'get',
        params,
    });
// 获取筛选单项数据
export const getCheckboxItem = params =>
    ajax({
        url: '/configuration/configure-echo/' + params.pageId,
        method: 'get',
    });
// 更新排序
export const sortCheckbox = data =>
    ajax({
        url: '/configuration/filter-order',
        method: 'post',
        data,
    });
// 获取添加时的各项内容

export const getCheckboxOptions = params =>
    ajax({
        url: '/configuration/attribute-echo/' + params.pageId + '/' + params.filterType,
        method: 'get',
        header: {
            contentType: 'application/x-www-form-urlencoded',
        },
    });

// 保存筛选数据
export const saveCheckboxItem = data =>
    ajax({
        url: '/configuration/attribute-update',
        method: 'post',
        data,
    });
