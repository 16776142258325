import { getUserList as getData } from '@/api/mkt/user.js';
export default {
    state: {
        params: { page: 1, size: 30 },
        page: {
            count: 0,
            page: 1,
        },
        list: [],
    },
    mutations: {
        setParams(state, data) {
            state.params = data;
            state.page.page = data.page || state.page.page;
        },
        setList(state, data) {
            state.list = data;
        },
        setTotal(state, data) {
            state.page.count = data;
        },
        setPage(state, data) {
            state.page = data;
        },
        clear(state) {
            state.list = [];
            state.params = { page: 1, size: 30 };
            state.page = {
                count: 0,
                page: 1,
            };
        },
    },
    actions: {
        list({ commit }) {
            // commit('setList', [{ name: 1, id: 2 }]);
            if (!getData) {
                return false; //
            }
            let obj = this.state['mkt-user'].params;
            obj.labels = obj.labelsArr;

            return new Promise(resolve => {
                getData(obj).then(res => {
                    console.log('----------getData', res);
                    if (res.code === 200) {
                        res.data.children.map(item => {
                            Object.keys(item).forEach(items => {
                                if (item[items] === null || item[items] === '') {
                                    item[items] = '--';
                                }
                            });
                        });
                        let data = res.data || [];
                        // if (data.children.length > 0) {
                        commit('setList', data.children);
                        let page = this.state['mkt-user'].page;
                        page.count = data.total;
                        commit('setPage', page);
                        resolve(data);
                        // }
                    }
                });
            });
        },
    },
};
