<template>
    <!-- 关注 粉丝 -->
    <div class="item-box">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="false"
            width="60%"
            :title="title"
            :before-close="close"
        >
            <div class="infor-item">
                <sc-table-v2
                    :head="tableHead"
                    :data="list"
                    :page="page"
                    :page-hide="false"
                    :read-table="true"
                    :load="load"
                    :pop-show="false"
                    :show-add="false"
                    :table-height="tableHeightCalc"
                    :selection="false"
                    ref="refTable"
                    @pageChange="pageChange"
                    @pageSizesChange="pageSizesChange"
                ></sc-table-v2>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getUserFans } from '@/api/mkt/user.js';

export default {
    name: 'browse-list',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    watch: {
        title: {
            immediate: true,
            handler(newVal) {
                if (newVal == '关注' || newVal == '粉丝') {
                    let obj = {
                        关注: 5,
                        粉丝: 6,
                    };
                    this.type = obj[newVal];
                    this.getUserFans();
                }
            },
        },
    },
    data() {
        return {
            load: false,
            addialogVisible: false,
            tableHead: [
                { name: '关注时间(互动时间)', key: 'createTime' },
                { name: '昵称', key: 'nickName' },
                { name: '手机号', key: 'mobile' },
            ],
            tableHeightCalc: window.innerHeight - 420,
            page: {
                size: 30,
                page: 1,
                count: 0,
            },
            list: [],
            type: '',
        };
    },
    methods: {
        getUserFans() {
            this.load = true;
            getUserFans({
                accountId: this.$route.query.accountId,
                page: this.page.page,
                size: this.page.size,
                type: this.type,
            }).then(res => {
                if (res.code == 200) {
                    this.load = false;
                    this.list = res.data.children;
                    this.page.count = res.data.total;
                }
            });
        },
        open(row) {
            this.addialogVisible = true;
        },
        close() {
            this.addialogVisible = false;
        },
        pageChange(val) {
            this.page.page = val;
            this.getUserFans();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getUserFans();
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    /deep/ .el-dialog__body {
        padding: 10px 20px !important;
    }
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
    }
}
</style>
