<template>
    <div class="page">
        页面找不着了！返回
        <a href="javascript:;" @click="pageFn">【登录】</a>
        页~
    </div>
</template>

<script>
export default {
    name: 'error',
    data() {
        return {};
    },
    methods: {
        pageFn() {
            this.$router.push({ path: '/login' });
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    width: 100%;
    height: 500px;
    line-height: 500px;
    text-align: center;
    font-size: 16px;
    a {
        color: #409eff;
    }
}
</style>
