import { operationList } from '@/api/mkt/tag.js';
import { getCompany } from '@/api/mkt/article-group.js';
import { getNoteTypeList } from '@/api/mkt/article.js';
import { getLabellist, getColumnId, getCategoryList, getLabelCommonList } from '@/api/mkt/topic.js';
import { getOssConfig } from '@/api/login.js';
import { getHouseLayoutList } from '@/api/mkt/exhibit.js';
import { spaceList, coverList } from '@/api/mkt/exhibit-group';
import { getDictList } from '@/api/common.js';

export default {
    data() {
        return {
            // 运营标签名
            operationList: [],
            operationListDis: [],
            // 分公司列表
            companyData: [],
            // 标签列表
            labelOptions: [],
            // 搜索标签列表
            searchLabelOptions: [],
            // 分类列表
            cloumnsList: [],
            // 空间列表
            spaceList: [],
            // 普通标签列表
            normalLabelList: [],
            // Oss数据
            ossConfig: {},
            houseList: [],
            // coverList
            coverList: [],
        };
    },
    methods: {
        getHouseLayoutList() {
            getHouseLayoutList().then(res => {
                if (res?.isSuccess) {
                    this.houseList = res.data || [];
                }
            });
        },
        getNoteTypeList() {
            getNoteTypeList().then(res => {
                if (res?.isSuccess) {
                    this.columns.noteType.options.options = res.data || [];
                }
            });
        },
        getOssConfig() {
            const params = {};
            getOssConfig(params)
                .then(res => {
                    if (res?.isSuccess) {
                        this.ossConfig = res.data || {};
                    }
                })
                .catch(err => {
                    console.log(err, 'err');
                });
        },
        getOperationList() {
            operationList().then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.operationList = res?.data || [];
                }
            });
        },
        getOperationListDis() {
            operationList().then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                        }
                    }
                    this.operationListDis = this.getTreeData(res?.data) || [];
                }
            });
        },
        getCompany() {
            getCompany().then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.label = item.ouName;
                            item.value = item.ouCode;
                        }
                    }
                    this.companyData = res?.data || [];
                }
            });
        },
        getTagList() {
            getLabellist({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.labelOptions = res?.data || [];
                }
            });
        },
        getSearchTagList() {
            getLabellist({}).then(res => {
                console.log(res, 'res-dddddd');
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                        }
                    }
                    this.searchLabelOptions = this.getTreeData(res?.data) || [];
                }
            });
        },
        getTreeData(data) {
            data.forEach(item => {
                if (
                    (item.children && item.children.length < 1) ||
                    item.children == null ||
                    item.children == undefined
                ) {
                    item.children = undefined;
                } else {
                    this.getTreeData(item.children);
                }
            });
            return data;
        },

        getColumn() {
            getCategoryList({ isShow: 0 }).then(res => {
                if (res?.isSuccess) {
                    this.cloumnsList = res.data.children || [];
                    console.log(this.cloumnsList, ' this.cloumnsList ');
                    // this.$store.commit('mkt-topic/setCategoryList', this.categoryList); //同步方法保存
                }
            });
        },
        getSpaceList() {
            spaceList().then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            // item.spaceName = item.spaceName;
                            item.children = item.childSpaces;
                            item.disabled = true;
                        }
                    }
                    this.spaceList = res?.data || [];
                }
            });
        },
        getCoverList(params) {
            coverList(params).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            // item.spaceName = item.spaceName;
                            item.children = item.childSpaces;
                            item.disabled = true;
                        }
                    }
                    this.coverList = res?.data || [];
                }
            });
        },
        getLabelCommonList() {
            getLabelCommonList({}).then(res => {
                if (res?.isSuccess) {
                    if (res?.data?.length) {
                        for (let item of res.data) {
                            item.labelName = item.groupName;
                            item.children = item.labelList;
                            item.disabled = true;
                        }
                    }
                    this.normalLabelList = res?.data || [];
                }
            });
        },
        changeLabel(arr, deleteData) {
            // 多选删除需要手动删除
            arr.map((item, index) => {
                if (deleteData == item.value) {
                    arr.splice(index, 1);
                }
            });
            // 处理关联关系有父级和子集的删除父级（后端要的数据格式，只要当前选中项）
            let newArr = [];
            arr.map(item => {
                if (item.parent) {
                    newArr.push(item.parent.value);
                }
            });
            arr.map((item, index) => {
                if (newArr.indexOf(item.value) > -1) {
                    arr.splice(index, 1);
                }
            });
            return {
                list: arr,
                deleteArr: newArr,
            };
            // 处理后端需要的数据
        },
        getLevelList() {
            // 获取评级筛选列表
            getDictList({ parentId: '295' }).then(res => {
                if (res?.isSuccess) {
                    this.levelList = res.data;
                }
            });
        },
    },

    created() {
        // 获取运营标签列表
        // this.getOperationList();
        // 获取分公司 列表
        // this.getCompany();
        // 获取标签列表
        // this.getTagList()
        // 获取搜索标签列表
        // this.getSearchTagList()
        //获取分类列表
        // this.getColumn()
        // 获取普通标签列表--不带运营标签
        // this.getLabelCommonList()
        // Oss接口
        // this.getOssConfig()
        // 处理标签、分类搜索多选的数据
        // this.changeLabel();
    },
};
