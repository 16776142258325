<template>
    <div class="add-tag-container">
        <el-dialog
            :visible.sync="addialogVisible"
            :close-on-click-modal="true"
            :title="title"
            @close="close"
            destroy-on-close
        >
            <sc-table-v2
                row-key="id"
                :head="tableHead"
                :data="listData"
                :page-hide="true"
                :load="isLoading"
                :mergeColumns="arraySpanMethod"
                ref="refTable"
            ></sc-table-v2>
            <div class="allTotal">合计：{{ standardPriceTotalAll }}元</div>
        </el-dialog>
    </div>
</template>
<script>
import { getGaisuanDetail } from '@/api/mkt/market';
import mixins from '@/mixins';
// 使用标准级品质
export default {
    props: {},
    components: {},
    mixins: [mixins],
    data() {
        return {
            title: '查看报价结果',
            addialogVisible: false,
            isLoading: false,
            listData: [],
            standardPriceTotalAll: 0,
            tableHead: [
                { name: '大类', key: 'productCategory' },
                {
                    name: '品类',
                    key: 'productType',
                },
                {
                    name: '配置说明',
                    key: 'remark',
                    width: 180,
                },
                {
                    name: '产品级别',
                    key: 'level',
                    formatter: val => {
                        return '标准级';
                    },
                },
                {
                    name: '参考品牌',
                    key: 'standardBrand',
                },
                {
                    name: '参考品质',
                    key: 'standardQuality',
                },
                {
                    name: '单位',
                    key: 'unitName',
                },
                {
                    name: '数量',
                    key: 'num',
                },
                {
                    name: '价格（元）',
                    key: 'standardPrice',
                },
                {
                    name: '小计（元）',
                    key: 'standardPriceTotalRow',
                },
            ],
        };
    },

    mounted() {},
    methods: {
        async open(row) {
            this.addialogVisible = true;
            if (!row?.priceCode) return;
            console.log('row.priceCode', row.priceCode);
            let res = await getGaisuanDetail({ priceCode: row.priceCode });
            if (res.code == 200) {
                let unitObj = {};
                res.data.forEach((d, index) => {
                    // d.id = 'a' + index;
                    // 计算大类下所有的小计
                    unitObj[d.productCategory] =
                        d.standardPriceTotal + (unitObj[d.productCategory] || 0);
                    this.standardPriceTotalAll += d.standardPriceTotal;
                });
                res.data.forEach(
                    d => (d.standardPriceTotalRow = unitObj[d.productCategory]?.toFixed(2))
                );
                this.listData = res.data;
                this.standardPriceTotalAll = this.standardPriceTotalAll.toFixed(2);
            }
        },
        close() {
            this.standardPriceTotalAll = 0;
            this.addialogVisible = false;
        },
        // 合并
        arraySpanMethod(row, column, rowIndex, columnIndex) {
            // console.log('--------------arraySpanMethod', row, column, rowIndex, columnIndex);
            if (columnIndex === 0 || columnIndex === 9) {
                let key = 'productCategory' || column.property; //指定根据那个参数进行合并行
                // 获取当前单元格的值
                const currentValue = row[key];
                // 获取上一行相同列的值
                const preRow = this.listData[rowIndex - 1];
                const preValue = preRow ? preRow[key] : null;
                // 如果当前值和上一行的值相同，则将当前单元格隐藏
                if (currentValue === preValue) {
                    return { rowspan: 0, colspan: 0 };
                } else {
                    // 否则计算当前单元格应该跨越多少行
                    let rowspan = 1;
                    for (let i = rowIndex + 1; i < this.listData.length; i++) {
                        const nextRow = this.listData[i];
                        const nextValue = nextRow[key];
                        if (nextValue === currentValue) {
                            rowspan++;
                        } else {
                            break;
                        }
                    }
                    return { rowspan, colspan: 1 };
                }
            }
        },
        // 合计
        totalMethod(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总价';
                    return;
                }

                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value)) && index == 1) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] += ' 元';
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },

        // 选中项回调方法
        changeFn(data) {
            //data:操作项数据
        },
        // 点击事件回调方法
        clickFn(data) {
            //data:操作项数据
            // console.log(data);
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item {
    width: 100%;
}
/deep/ .el-dialog__body {
    padding-top: 0 !important;
}
/deep/.el-drawer__body {
    padding-left: 20px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0;
}
.dialog-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
}
.must {
    color: red;
}

/deep/ .upload-row {
    width: 90px;
    height: 90px;
}
/deep/ .esay-style .el-form-item__error {
    top: 103% !important;
}
.is-error {
    color: #f56c6c;
    font-size: 12px;
}
.allTotal {
    text-align: right;
    font-weight: 700;
    padding-right: 10px;
    font-size: 14px;
}
</style>
