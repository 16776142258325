import rq from '@/utils/https.js';
const ajax = rq.requests;

// 获取分类列表
export const getSyscategoryList = params =>
    ajax({
        url: 'category/list',
        method: 'get',
        params,
    });
//添加分类
export const addCategory = data =>
    ajax({
        url: 'category/add',
        method: 'post',
        data,
    });
//获取分类详情
export const getCategoryinfo = params =>
    ajax({
        url: `category/info/${params}`,
        method: 'get',
    });
//编辑分类
export const editCategory = data =>
    ajax({
        url: 'category/edit',
        method: 'put',
        data,
    });
//删除分类
export const delCategory = params =>
    ajax({
        url: `category/del/${params}`,
        method: 'post',
    });
//获取栏目
export const getdetailColumn = params =>
    ajax({
        url: `category/getcolumn/${params}`,
        method: 'get',
    });
//获取分类栏目
export const getCategorycolumn = params =>
    ajax({
        url: `columns/list`,
        method: 'get',
        params,
    });
//批量保存分类栏目
export const addColumsbatch = data =>
    ajax({
        url: 'category/addcolumsbatch',
        method: 'post',
        data,
    });
//获取保存分类栏目
export const getCheckcolumn = params =>
    ajax({
        url: `category/columnAll/${params}`,
        method: 'get',
    });
//批量保存分类栏目
export const editCatestate = data =>
    ajax({
        url: 'category/editstate',
        method: 'put',
        data,
    });
//获取二维码
export const getQrcode = params =>
    ajax({
        url: 'wechat/scan/code/preview',
        method: 'get',
        params,
    });
