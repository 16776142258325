import rq from '@/utils/https.js';
const ajax = rq.requests;

// 获取概算列表
export const getGaisuanList = data =>
    ajax({
        url: '/user/quote-sys-list',
        method: 'post',
        data,
    });
// 获取概算详情

export const getGaisuanDetail = params =>
    ajax({
        url: '/user/quote-sys-result',
        method: 'get',
        params,
    });
// 获取广告位详情
export const getRotaion = params =>
    ajax({
        url: `decoration/info/${params}`,
        method: 'get',
    });
//添加/编辑广告位
export const addEcoration = data =>
    ajax({
        url: 'decoration/add',
        method: 'post',
        data,
    });
// 内容运营列表
export const contentRunList = params =>
    ajax({
        url: 'decoration/content-run-list',
        method: 'get',
        params,
    });
// 跳转链接
export const getLink = params =>
    ajax({
        url: `decoration/treetype-new`,
        method: 'get',
        params,
    });
// 获取图文分页
export const getDeclist = params =>
    ajax({
        url: 'decoration/list',
        method: 'get',
        params,
    });
// 模块回显
export const moduleEcho = params =>
    ajax({
        url: `/decoration/module-echo/${params}`,
        method: 'get',
    });
// 广告图-图文-内容-分页
export const getModuleList = params =>
    ajax({
        url: '/decoration/list',
        method: 'get',
        params,
    });
// 批量上下架广告位
export const hitTheShelfSoldOut = data =>
    ajax({
        url: '/decoration/editstate',
        method: 'put',
        data,
    });
// 批量删除广告位
export const moduleListDelete = data =>
    ajax({
        url: '/decoration/del',
        method: 'delete',
        data,
    });
// 灵感池列表
export const getPoolist = data =>
    ajax({
        url: '/decoration/poollist',
        method: 'post',
        data,
    });
// 跳转链接
export const getLinkdetail = params =>
    ajax({
        url: `/decoration/treedetail/${params}`,
        method: 'get',
    });
// 跳转链接
export const getTextimglist = params =>
    ajax({
        url: `/decoration/list`,
        method: 'get',
        params,
    });
// 图文排序
export const orderDec = data =>
    ajax({
        url: '/decoration/navigation',
        method: 'post',
        data,
    });
// 删除
export const delDec = data =>
    ajax({
        url: `/decoration/del`,
        method: 'delete',
        data,
    });
// 文章-专题
export const getDecspec = () =>
    ajax({
        url: `/decoration/specify/1/500`,
        method: 'get',
    });
// 文章-专题
export const getDectopic = () =>
    ajax({
        url: `/decoration/specifyTopic/1/500`,
        method: 'get',
    });
// 文章-设计师
export const getDecdesign = params =>
    ajax({
        url: `/decoration/specify/design/1/500/${params}`,
        method: 'get',
    });
// 保存灵感集
export const saveDec = data =>
    ajax({
        url: '/decoration/save',
        method: 'post',
        data,
    });
// 获取分类列表
export const getCategoryList = params =>
    ajax({
        url: '/category/list',
        method: 'get',
        params,
    });
// 获取标签组列表
export const getLabellist = params =>
    ajax({
        url: '/labels/group/list',
        method: 'get',
        params,
    });
// 文章-列表
export const getArticle = data =>
    ajax({
        url: `/decoration/poollist-article`,
        method: 'post',
        data,
    });
// 保存文章
export const saveartDec = data =>
    ajax({
        url: '/decoration/save-articledetail',
        method: 'post',
        data,
    });
// /user-ad-management/homeAdManagementList
export const getAdManagementList = data =>
    ajax({
        url: '/user-ad-management/homeAdManagementList',
        method: 'post',
        data,
    });
// /user-ad-management/isShelfHomeAd
export const isShelfHomeAd = data =>
    ajax({
        url: '/user-ad-management/isShelfHomeAd',
        method: 'post',
        data,
    });
// /user-ad-management/isShelfCheck
export const isShelfCheck = data =>
    ajax({
        url: '/user-ad-management/isShelfCheck',
        method: 'post',
        data,
    });
// /user-ad-management/getAdJumpTypeList
export const getAdJumpTypeList = params =>
    ajax({
        url: '/user-ad-management/getAdJumpTypeList',
        method: 'get',
        params,
    });
// /user-ad-management/getHomeAdById
export const getHomeAdById = params =>
    ajax({
        url: '/user-ad-management/getHomeAdById',
        method: 'get',
        params,
    });
// /user-ad-management/saveHomeAd
export const saveHomeAd = data =>
    ajax({
        url: '/user-ad-management/saveHomeAd',
        method: 'post',
        data,
    });
