<template>
    <!-- 业主需求 -->
    <div class="item-box">
        <div class="top-line">
            <div class="tit">装修报价</div>
            <div class="right" v-if="active == '999' && list.length == 4">
                <el-button type="text" @click="handleCheckTab()" size="mini">
                    查看更多
                </el-button>
            </div>
        </div>
        <div class="infor-item">
            <sc-table-v2
                v-if="active == '7' || list.length"
                row-key="priceCode"
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="false"
                :pop-show="false"
                :show-add="false"
                :show-add-config="tableAddConfig"
                :table-height="tableHeightCalc"
                :read-table="true"
                :load="load"
                @change="changeFn"
                @click="clickFn"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:houseString>
                    <el-table-column min-width="110">
                        <template slot="header">户型</template>
                        <template slot-scope="scope">
                            <div>
                                <el-popover
                                    placement="top-start"
                                    popper-class="cus-popover"
                                    title=""
                                    trigger="hover"
                                >
                                    <div slot="reference" class="row-value">
                                        {{ scope.row.houseString }}
                                    </div>
                                    <div slot class="popover-content-list">
                                        <div
                                            class="item"
                                            v-for="(item, index) in scope.row.houses"
                                            :key="index"
                                        >
                                            <span class="name">{{ item.house }}</span>
                                            <span class="num">数量{{ item.houseNum }}</span>
                                            <span class="area">
                                                总地面面积{{ item.houseArea }}㎡
                                            </span>
                                        </div>
                                    </div>
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                </template>

                <template v-slot:control>
                    <el-table-column label="操作" fixed="right" width="96">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleDetail(scope.row)" size="mini">
                                查看报价结果
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
            <!-- <sc-table-v2
                v-if="active == '6' || list.length"
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="pageHide"
                :read-table="true"
                :load="load"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :selection="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <template v-slot:edit>
                    <el-table-column label="操作" fixed="right" width="60">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="goDetail(scope.row)">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2> -->
        </div>
        <detail-dialog ref="details" @handleClose="queryDataMixin"></detail-dialog>
    </div>
</template>
<script>
import detailDialog from '../../../mkt-management/gaisuan/details.vue';
import { getGaisuanList } from '@/api/mkt/market.js';

export default {
    name: 'browse-list',
    components: { detailDialog },
    props: {
        tabActive: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tabCurrent: '11',
            tabList: [
                { label: '居住者信息', id: '11', num: '' },
                { label: '房屋信息', id: '22', num: '' },
                { label: '设计需求', id: '33', num: '' },
                { label: '大类', id: '44', num: '' },
            ],
            active: '',
            load: false,
            pageHide: false,
            tableHeightCalc: null,
            page: {
                size: 4,
                page: 1,
                count: 0,
            },
            list: [],
            tableHead: [
                { name: '记录编号', key: 'priceCode', width: 250 },
                {
                    name: '来源',
                    key: 'quoteSource',
                    width: 86,
                },
                {
                    name: '用户',
                    key: 'startTime',
                    minWidth: 180,
                    formatter: val => {
                        return `${val.nickName || ''}
                            ${((val.mobile && val.nickName && '(') || '') +
                                val.mobile +
                                ((val.mobile && val.nickName && ')') || '')}`;
                    },
                },
                {
                    name: '房屋类型',
                    key: 'houseType',
                    width: 86,
                },
                {
                    name: '套内面积（㎡）',
                    key: 'insideArea',
                    width: 110,
                },
                {
                    name: '楼层（层）',
                    key: 'floorNum',
                    width: 86,
                },
                {
                    name: '层高（米）',
                    key: 'floorHeight',
                    width: 86,
                },
                {
                    name: '户型',
                    key: 'houseString',
                },
                {
                    name: '所在城市',
                    key: 'city',
                    formatter: val => {
                        return (val.province || '') + (val.city || '') + (val.region || '');
                    },
                },
                {
                    name: '是否有采暖',
                    key: 'isHeating',
                    formatter: val => {
                        return val.isHeating == 1 ? '有' : '无';
                    },
                    width: 86,
                },
                {
                    name: '提交时间',
                    key: 'bpmCreateTime',
                    width: 140,
                },
                { name: '操作', key: 'control' },
            ],
        };
    },

    watch: {
        tabActive: {
            immediate: true,
            handler(newVal) {
                this.list = [];
                this.active = newVal;
                if (newVal == '999') {
                    this.tableHeightCalc = null;
                    this.page.size = 4;
                    this.pageHide = true;
                } else if (newVal == '7') {
                    this.tableHeightCalc = window.innerHeight - 500;
                    this.page.size = 30;
                    this.pageHide = false;
                }
                if (this.$route.query.token) {
                    setTimeout(async () => {
                        this.getUserQuestionList();
                    }, 800);
                } else {
                    this.getUserQuestionList();
                }
            },
        },
    },
    methods: {
        async getUserQuestionList() {
            let params = {
                page: this.page.page,
                size: this.page.size,
                accountId: this.$route.query.accountId,
            };
            this.load = true;
            let { isSuccess, data } = await getGaisuanList(params);
            console.log('-----------------------', isSuccess, data);
            if (isSuccess) {
                this.load = false;
                for (let item of data.children) {
                    item.houseString = this.houseFormat(item);
                    item.province = ['北京市', '天津市', '上海市', '重庆市'].includes(item.province)
                        ? ''
                        : item.province;
                }
                this.list = data.children;
                this.page.count = data.total;
                data.total && this.$emit('setTabNum', { type: 7, num: data.total });
            } else {
                this.load = false;
            }
        },
        houseFormat(row) {
            let arr = [
                { name: '卧室', unit: '室' },
                { name: '公区', unit: '厅' },
                { name: '厨房', unit: '厨' },
                { name: '卫生间', unit: '卫' },
            ];
            let house = '';
            for (let item of arr) {
                let num = row?.houses?.find(space => space.house == item.name)?.houseNum;
                house += (num && num + item.unit) || '';
            }
            return house;
        },
        handleDetail(row) {
            this.$refs.details.open(row);
        },
        pageChange(val) {
            this.page.page = val;
            this.getUserQuestionList();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getUserQuestionList();
        },
        handleClick(tab) {
            this.tabCurrent = tab.name;
        },

        handleCheckTab() {
            this.$parent.handleClick({ label: '装修报价', name: '7' });
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
        margin-top: 10px;
    }
}

.row-value {
    color: #4290f7;
}
.cus-popover {
    .row-value {
        color: #4290f7;
    }
    .popover-content-list {
        min-width: 350px;
        font-size: 12px;
        .item {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #efefef;
        }

        .name {
            display: inline-block;
            width: 100px;
            font-weight: 700;
        }
        .num {
            width: 60px;
        }
        .area {
            width: 120px;
            text-align: left;
        }
    }
}
</style>
