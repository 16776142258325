import { getTagList as getData } from '@/api/mkt/tag-group.js';
export default {
    state: {
        params: {
            page: 1,
            size: 30,
        },
        page: {
            count: 0,
            page: 1,
            size: 30,
        },
        list: [],
    },
    mutations: {
        setParams(state, data) {
            state.params = { ...state.params, ...data };
        },
        setTotal(state, data) {
            state.page.count = data;
        },
        setList(state, data) {
            state.list = data;
        },

        clear(state) {
            state.list = [];
        },
    },
    actions: {
        list({ commit }) {
            if (!getData) {
                return false; //
            }
            return new Promise(resolve => {
                // let a = this.state['mkt-tag-group'].params.searchFileName;
                // let b = this.state['mkt-tag-group'].params.searchFileValue;
                console.log(this.state['mkt-tag-group'].params, 999);
                getData(this.state['mkt-tag-group'].params).then(res => {
                    // if (res.code === 200) {
                    res.data.children.map(item => {
                        Object.keys(item).forEach(items => {
                            if (item[items] === null || item[items] === '') {
                                item[items] = '--';
                            }
                        });
                    });
                    let data = res.data || [];
                    console.log(data, 'vuex---data', data.total);
                    // if (data.length > 0) {
                    commit('setList', data.children);
                    commit('setTotal', data.total);
                    resolve(data);
                    // }
                    // }
                });
            });
        },
    },
};
