let bpmUrl = 'https://testbpm.voglassdc.com'; //测试环境接口
let caseUrl = 'https://testcase.voglassdc.com';
let ossConfig = {
    bucket: 'sczs',
    region: 'oss-cn-beijing',
    folderName: 'app-scj/test/market', //营销存储文件夹 【test,master】
};
let uploadConfig = {
    oss: {
        url: 'https://sczs.voglassdc.com/', //oss访问域名
        accessKeyId: '',
        accessKeySecret: '',
        stsToken: '',
        bucket: 'sczs',
        region: 'oss-cn-beijing',
    },
    ENV: 'test', //环境变量（保存文件到对应文件夹下）【test、pre、master】
    fileModule: 'app-scj/test/market', //业务模块名称（保存文件到对应文件夹下）
};
// let uploadConfig = {
//     oss: {
//         url: 'http://dpwcc.voglassdc.com/', //oss访问域名
//         accessKeyId: '',
//         accessKeySecret: '',
//         stsToken: '',
//         bucket: 'dpwcc',
//         region: 'oss-cn-beijing',
//     },
//     ENV: 'test', //环境变量（保存文件到对应文件夹下）【test、pre、master】
//     fileModule: 'market', //业务模块名称（保存文件到对应文件夹下）
// };

switch (location.host) {
    case 'testmarket.voglassdc.com':
        uploadConfig.ENV = 'test';
        bpmUrl = 'https://testbpm.voglassdc.com';
        caseUrl = 'https://testcase.voglassdc.com';
        ossConfig.folderName = 'app-scj/test/market';
        break;
    case 'premarket.voglassdc.com':
        uploadConfig.ENV = 'pre';
        bpmUrl = 'https://yf.voglassdc.com';
        caseUrl = 'https://precase.voglassdc.com';
        ossConfig.folderName = 'app-scj/pre/market';
        break;
    case 'market1.voglassdc.com':
        uploadConfig.ENV = 'master'; //
        bpmUrl = 'https://mapi.voglassdc.com';
        ossConfig.folderName = 'app-scj/master/market';
        break;
    case 'market.voglassdc.com':
        uploadConfig.ENV = 'master'; //
        caseUrl = 'https://case.voglassdc.com';
        bpmUrl = 'https://mapi.voglassdc.com';
        ossConfig.folderName = 'app-scj/master/market';
        break;
    default:
        uploadConfig.ENV = 'test';
        bpmUrl = 'https://testbpm.voglassdc.com';
        caseUrl = 'https://testcase.voglassdc.com';
        ossConfig.folderName = 'app-scj/test/market';
        break;
}
export const oss = ossConfig;
export const uploadConfigData = uploadConfig;
export const BPMURL = bpmUrl;
export const CASEURL = caseUrl;

export default {};
