<template>
    <div class="breadcrumb">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="item in list" :key="item" :to="item.path">
                {{ item }}
            </el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>活动管理</el-breadcrumb-item>
            <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb> -->
    </div>
</template>

<script>
export default {
    name: 'breadcrumb',
    data() {
        return {
            list: [],
        };
    },

    watch: {
        $route: {
            handler(route) {
                let List = route.matched;
                let newList = [];
                List.forEach(item => {
                    newList.push(item.name);
                });
                this.list = newList;
            },
            immediate: true,
        },
    },
    methods: {},
};
</script>

<style lang="less" scoped>
.breadcrumb {
    padding: 15px 0 10px;
}
</style>
