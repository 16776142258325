<template>
    <!-- 个人基本信息 -->
    <div class="basic-infor">
        <div class="tit">个人基本信息</div>
        <div class="infor-box">
            <div class="img-box">
                <el-image
                    v-if="data?.imgUrl"
                    :src="data?.imgUrl"
                    class="img"
                    fit="cover"
                    :preview-src-list="[data?.imgUrl]"
                ></el-image>
                <img v-else class="img" src="@/assets/images/default-avatar.png" />
            </div>
            <div class="infor">
                <div class="line1">
                    <div v-if="data?.nickName">昵称：{{ data && data.nickName }}</div>
                    <div v-if="data?.identity">身份：{{ data.identity }}</div>
                    <div v-if="data?.role">角色：{{ data.role }}</div>
                    <div v-if="data?.identity != '非员工' && data.account">
                        bpm账号：{{ data.account }}
                    </div>
                    <div v-if="data.identity != '非员工' && data.job">岗位名：{{ data.job }}</div>
                    <div v-if="data.identity != '非员工' && data.companyName">
                        分公司：{{ data.companyName }}
                    </div>
                    <div v-if="data.identity != '非员工' && data.detpName">
                        部门：{{ data.detpName }}
                    </div>
                </div>

                <div class="line1">
                    <div v-if="data.sex">性别：{{ data.sex | userSex }}</div>
                    <div v-if="$route.query.ipRegion && $route.query.ipRegion != 'null'">
                        IP地址：{{ $route.query.ipRegion }}
                    </div>
                    <div v-if="data.mobile">手机号：{{ data.mobile }}</div>
                </div>

                <div class="line3">
                    <!-- 2 4 -->
                    <div @click="openLikeDialog('点赞', numData.praiseNum)">
                        点赞：{{ numData.praiseNum }}条
                    </div>
                    <div @click="openFollowDialog('关注', numData.invNum)">
                        关注：{{ numData.invNum }}条
                    </div>
                    <div @click="openLikeDialog('收藏', numData.favoriteNum)">
                        收藏：{{ numData.favoriteNum }}条
                    </div>
                    <div @click="openFollowDialog('粉丝', numData.fansNum)">
                        粉丝：{{ numData.fansNum }}条
                    </div>
                    <div @click="openLikeDialog('评论', numData.commentNum)">
                        评论：{{ numData.commentNum }}条
                    </div>
                    <div @click="openLikeDialog('分享', numData.shareNum)">
                        分享：{{ numData.shareNum }}条
                    </div>
                </div>
            </div>
        </div>

        <likeDialog ref="likeDialog" :title="title"></likeDialog>
        <followDialog ref="followDialog" :title="title"></followDialog>
    </div>
</template>

<script>
import likeDialog from './like-dialog'; //点收评分
import followDialog from './follow-dialog'; //点收评分

export default {
    name: 'basic-infor',
    components: {
        likeDialog,
        followDialog,
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        numData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            title: '',
        };
    },
    filters: {
        userIdentity(val) {
            const obj = {
                1: '设计师',
                2: '内部员工',
                3: '普通用户',
                4: '潜在客户',
                5: '客户',
                6: '马甲',
            };
            return obj[val] || '--';
        },
        userSex(val) {
            const obj = {
                1: '男',
                2: '女',
                3: '未知',
            };
            return obj[val] || '--';
        },
    },
    methods: {
        openLikeDialog(title, num) {
            if (num) {
                this.title = title;
                this.$refs.likeDialog.open();
            }
        },
        openFollowDialog(title, num) {
            if (num) {
                this.title = title;
                this.$refs.followDialog.open();
            }
        },
    },
};
</script>

<style lang="less" scoped>
.basic-infor {
    padding: 10px;
    .tit {
        font-size: 14px;
        color: #333333;
    }
    .infor-box {
        display: flex;
        margin-top: 10px;
        width: 100%;
        padding: 16px;
        border: 1px solid #dcdfe6;
        border-radius: 8px;
        .img-box {
            width: 70px;
            height: 70px;
            flex: none;
            .el-image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .infor {
            margin-left: 20px;
            .line1 {
                height: 20px;
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                margin-bottom: 4px;

                color: #333333;
                div {
                    margin-right: 14px;
                }
            }
            .line3 {
                display: flex;
                padding: 4px 0px 4px 14px;
                border: 1px solid rgba(215, 215, 215, 1);
                background-color: rgba(242, 242, 242, 1);
                border-radius: 8px;
                width: fit-content;
                div {
                    cursor: pointer;
                    margin-right: 16px;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 12px;
                    color: #02a7f0;
                }
            }
        }
    }
}
</style>
