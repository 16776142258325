<template>
    <div class="mkt">
        <div class="tool-bar-main" v-if="!token">
            <el-button type="primary" size="mini" @click="backHandle">返回</el-button>
        </div>
        <basicInfor :data="userData.userInfoProfileVo" :numData="userData"></basicInfor>
        <div>
            <el-tabs v-model="tabActive" @tab-click="handleClick">
                <el-tab-pane
                    :label="item.label + item.num"
                    :key="item.id"
                    :name="item.id"
                    v-for="item in tabLists"
                ></el-tab-pane>
            </el-tabs>
            <div class="tab-main">
                <demand
                    v-if="tabActive == '999' || tabActive == '6'"
                    :tabActive="tabActive"
                    :data="userData"
                    @setTabNum="setTabNum"
                ></demand>
                <gaisuan-list
                    v-if="tabActive == '999' || tabActive == '7'"
                    :tabActive="tabActive"
                    :data="userData"
                    @setTabNum="setTabNum"
                ></gaisuan-list>
                <inteMatching
                    v-if="tabActive == '999' || tabActive == '0'"
                    :data="userData"
                ></inteMatching>
                <styleTesting
                    v-if="tabActive == '999' || tabActive == '1'"
                    :data="userData.styleResult"
                    :answers="userData.answers"
                ></styleTesting>
                <adver-list
                    v-if="tabActive == '999' || tabActive == '5'"
                    :data="userData.budget"
                ></adver-list>
                <browseList
                    :tabActive="tabActive"
                    @setTabNum="setTabNum"
                    v-if="tabActive == '999' || tabActive == '2'"
                ></browseList>
                <reservationList
                    :tabActive="tabActive"
                    @setTabNum="setTabNum"
                    v-if="tabActive == '999' || tabActive == '3'"
                ></reservationList>
                <contentList
                    :tabActive="tabActive"
                    @setTabNum="setTabNum"
                    v-if="tabActive == '999' || tabActive == '4'"
                ></contentList>
            </div>
        </div>
    </div>
</template>

<script>
import demand from './components/demand'; //个人信息
import gaisuanList from './components/gaisuan-list'; //个人信息

import basicInfor from './components/basic-infor'; //个人信息
import inteMatching from './components/inte-matching'; //智能、客户需求
import styleTesting from './components/style-testing'; //风格测试
import browseList from './components/browse-list'; //浏览记录
import reservationList from './components/reservation-list'; //预约记录
import contentList from './components/content-list'; //内容发布
import adverList from './components/adver-list'; //户型规划/装修预算
import { setCookid, getCookie } from '@/utils/util.js';
import { getProfileInformation } from '@/api/mkt/user.js';
import { validToken } from '@/api/common.js';
export default {
    name: 'article',
    components: {
        demand,
        gaisuanList,
        basicInfor,
        inteMatching,
        styleTesting,
        browseList,
        reservationList,
        contentList,
        adverList,
    },

    data() {
        return {
            userData: {},
            tabActive: '999',
            tabList: [
                { label: '全部', id: '999', num: '' },
                { label: '需求分析', id: '6', num: '' },
                { label: '装修报价', id: '7', num: '' },
                { label: '智能匹配/客户需求收集', id: '0', num: '' },
                { label: '风格测试', id: '1', num: '' },
                { label: '户型规划/装修预算', id: '5', num: '' },
                { label: '浏览记录', id: '2', num: '' },
                { label: '预约记录', id: '3', num: '' },
                { label: '内容发布', id: '4', num: '' },
            ],
            token: '',
        };
    },
    created() {
        let token = this.$route.query.token;
        let identity = this.$route.query.identity;
        this.validTokenFn(token, identity);
        console.log('--------------1111', token, identity);
    },
    methods: {
        async validTokenFn(token, identity) {
            if (token) {
                this.token = token;
                setCookid('AuthorizationBe', token, 1);
                let res = await validToken();
                console.log('--------------res', res);
                if (res.isSuccess) {
                    setTimeout(async () => {
                        this.setTabs(identity);
                    }, 800);
                }
                // this.updateHistory();
            } else {
                this.setTabs(identity);
            }
            await this.getProfileInformation();
            this.setTabNum({
                type: 5,
                num: (this.userData.budget && this.userData.budget.length) || 0,
            });
        },
        setTabs(identity) {
            if (identity == '游客') {
                this.tabActive = '5';
                this.tabLists = this.tabList.filter(d => ['2', '5'].includes(d.id));
            } else {
                this.tabLists = this.tabList;
            }
        },
        updateHistory() {
            let currentUrl = window.location.href;
            let urlParts = currentUrl.split('?');
            let urlParams = urlParts[1];
            let number = urlParams.indexOf('token') - 1;
            urlParams = urlParams.slice(0, number);
            let queryParams = new URLSearchParams(urlParams);
            let newUrl = `${urlParts[0]}?${queryParams.toString()}`;
            // console.log('--------queryParams', queryParams, newUrl);
            history.pushState({}, '', newUrl);
        },
        async getProfileInformation() {
            const params = {
                accountId: this.$route.query.accountId,
                terminalIdentification: this.$route.query.terminalIdentification,
            };
            const res = await getProfileInformation(params);
            if (res?.isSuccess) {
                this.userData = res.data;
            }
        },
        setTabNum(data) {
            let num = (data.num && '(' + data.num + ')') || '';
            this.tabLists.find(d => d.id == data.type).num = num;
        },
        handleClick(tab) {
            this.tabActive = tab.name;
        },
        // 返回方法
        async backHandle() {
            // 关闭当前页面
            window.close();
            // this.$router.push('/mkt/user/archives');
        },
    },
};
</script>
<style lang="less" scoped>
.tool-bar-main {
    padding: 20px 0 0;
    text-align: right;
    padding-right: 50px;
}
.tab-main {
    padding: 0 10px 10px 10px;
}
</style>
