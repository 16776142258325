<template>
    <div class="product-navbar">
        <div class="product-navbar-stage">
            <!-- <div class="product-navbar-title">
                {{ menuH1 }}
            </div> -->
            <div class="product-navbar-list">
                <ul>
                    <li
                        v-for="(item, index) in filterMenu"
                        :key="index"
                        @click.stop="menuItemFn(item)"
                        :class="{ active: item.path == path }"
                        v-show="item.show"
                    >
                        <a href="javascript:;">
                            <i class="icon-font icon-diy-after" :class="item.icon"></i>
                            <span class="product-title">{{ item.name }}</span>
                            <i
                                class="icon-font upDown"
                                :class="item.showChild ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                                v-if="item.children && item.children.length > 0"
                            ></i>
                        </a>
                        <div
                            class="child"
                            v-if="item.children && item.children.length > 0"
                            v-show="item.showChild"
                        >
                            <ul>
                                <li
                                    v-for="(i2, index) in item.children"
                                    :key="index"
                                    @click.stop="menuItemLocation(i2)"
                                    :class="{ active: i2.path == path }"
                                    v-show="i2.show"
                                >
                                    <a href="javascript:;">
                                        <span class="product-title">
                                            {{ i2.name }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <!-- <li class="active">
						<a href="javascript:;">
							<span class="product-title iconfontbpm">&#x0eb37;</span>
						</a>
					</li> -->
                </ul>
            </div>
        </div>
        <div class="product-navbar-collapse" @click="menuFn">
            <div class="product-navbar-collapse-bg"></div>
            <div class="product-navbar-collapse-bar">
                <i class="el-icon-s-fold"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { treeFlat, treeFindPath } from '@/utils/tree';
import localRouterList from '@/router/test.js';
export default {
    name: 'IframeHead',
    data() {
        return {
            path: '',
            menuH1: '权限管理',
            menu: [],
            localList: [], //本地扁平化后的路由
        };
    },
    computed: {
        filterMenu() {
            return this.menu.filter(item => {
                return (
                    item.path &&
                    item.path != '*' &&
                    item.path != '/login' &&
                    item.path != '/404' &&
                    item.path != '/userinfo' &&
                    item.path != '/jump-sczshome' &&
                    item.path != '/jump-scj'
                );
            });
        },
    },
    // watch: {
    //     $route: {
    //         handler: function(val) {
    //             this.initMenu();
    //         },
    //     },
    // },
    created() {},
    mounted() {
        this.initMenu();
    },
    methods: {
        initMenu() {
            let constantRoutes = this.$router.options.routes;
            let authRouter = this.$store.state.userInfo.routerList;
            let menu = constantRoutes.concat(authRouter);
            this.menu = menu;

            const path = this.$router.currentRoute.path;
            this.path = path;
            let checkPath = treeFindPath(
                menu,
                {
                    childKey: 'children',
                    pathKey: 'path',
                    path: [],
                },
                node => node.path === this.path
            );
            let outMenu = [
                '新增PPT',
                'PPT页',
                '新增节点PPT',
                '新增资料',
                '新增节点',
                '添加分类',
                '添加产品分类',
                '添加设计分类',
                '添加筛选',
                '编辑品牌',
                '编辑空间',
                '编辑产品',
                '新增标签',
                '新增标签组',
                '新增标签',
                '新增标签组',
                '设计平台轮播图',
                '产品平台轮播图',
                '产品平台分类',
                '设计平台分类',
                '公告栏',
                '平台精选',
                '用户上传',
                '新增短信群发',
                '最小产品单元',
                '标准化产品单元',
                '成组最小产品单元',
                '新增套餐',
                '套餐管理-新增',
                '站内推送',
                '运营位配置',
                '关联笔记',
                '潜客详情',
                '案例动态详情',
                '提报奖项',
                '用户档案统计详情',
                '新增长图文',
                '内容合集详情',
                '设计师榜详情',
            ];
            treeFlat(menu, 'children', item => {
                if (item.path == checkPath[0]) {
                    item.showChild = true;
                } else {
                    item.showChild = false;
                }
                item.show = true;
                if (outMenu.indexOf(item.name) > -1) {
                    item.show = false;
                }
                if (
                    item.children &&
                    item.children.length &&
                    item.children.filter(child => child.show).length == 0
                ) {
                    item.showChild = false;
                    item.show = false;
                }
            });
            let localList = [];
            treeFlat(localRouterList, 'children', item => {
                localList.push(item);
            });
            this.localList = localList;
        },
        menuFn() {
            this.$emit('menu');
        },
        menuItemFn(item) {
            if (!item.children || (item.children && !item.children.length)) {
                this.$router.push({ path: item.path });
            }
            item.showChild = !item.showChild;
        },
        menuItemLocation(item) {
            if (item && item.path) {
                if (this.localList.findIndex(i => i.path === item.path) !== -1) {
                    const path = this.$router.currentRoute.path;
                    if (item.path != path) {
                        this.path = item.path;
                        this.$router.push({ path: item.path });
                    }
                } else {
                    location.reload();
                    // this.$router.push({ path: '/404' });
                }
            }
        },
    },
};
</script>

<style lang="less" scoped>
.frame__left {
    overflow: hidden;
    position: fixed;
    width: 50px;
    height: 100%;
    background-color: #464c5b;
    left: 0;
    top: 0;
    margin-top: 60px;
    z-index: 3;
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    overflow-y: auto;
    z-index: 200;
    &.active {
        cursor: pointer;
        width: 230px;
        // -webkit-transition-delay: .3s;
        // transition-delay: .3s;
    }
    ul {
        // height: calc(100% - 48px);
        // width: calc(100% + 20px);
        overflow-y: auto;
        margin: 0;
        position: relative;
        padding-bottom: 60px;
        .slider-item {
            color: #fff;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            // position: absolute;
            -webkit-transition: all 0.2s ease-out 0s;
            transition: all 0.2s ease-out 0s;
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 0;
            cursor: pointer;
            .slider-item-link {
                text-decoration: none;
                .slider-item-name {
                    display: inline-block;
                    vertical-align: middle;
                    width: 178px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: rgba(255, 255, 255, 0.65);
                    font-size: 14px;
                    padding-right: 8px;
                }
            }
            .slider-item-icon-box {
                width: 50px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
                i {
                    font-size: 18px;
                    margin-top: 10px;
                    color: rgba(255, 255, 255, 0.65);
                }
                .slider-item-icon {
                    font-size: 18px;
                    vertical-align: middle;
                    color: rgba(255, 255, 255, 0.65);
                    top: 0;
                }
            }
            &:hover,
            &.active {
                background-color: #2e364a;
                & .slider-item-link .slider-item-name,
                & i {
                    color: white;
                }
            }
            &:hover,
            &.active {
                .slider-item-icon {
                    color: white;
                }
            }
        }
    }
}

.product-navbar {
    width: 0px;
    font-size: 14px;
    float: left;
    background-color: #1e222d;
    color: #fff;
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    z-index: 2;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    // z-index: 2001;
    .product-navbar-stage {
        width: 0;
        overflow: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        -o-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        .product-navbar-title {
            font-size: 16px;
            width: 180px;
            height: 50px;
            line-height: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;
            text-indent: 20px;
            text-align: left;
        }
        .product-navbar-list {
            margin-top: 10px;
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1;
            &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
            }
            &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #344158;
            }
            &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #1e222d;
            }
            ul {
                list-style: none;
                padding: 0px;
                margin: 0px;
                li {
                    a {
                        width: 180px;
                        height: 38px;
                        line-height: 38px;
                        display: flex;
                        color: #fff;
                        display: flex;
                        padding-left: 12px;
                        cursor: pointer;
                        position: relative;
                        &:hover {
                            background-color: #262f3e;
                        }
                        &.active {
                            background-color: white;
                        }
                        .icon-font {
                            padding-top: 8px;
                            padding-right: 5px;
                        }
                        .icon-diy-after {
                            font-size: 14px;
                            color: #ccc;
                            padding: 13px 12px 0 0;
                        }
                        .upDown {
                            position: absolute;
                            right: 15px;
                            top: 6px;
                            font-size: 12px;
                            -webkit-transform: scale(0.84, 0.84);
                            *font-size: 10px;
                            color: #ccc;
                        }
                    }
                    &.active > a {
                        background-color: #006eff;
                    }
                    .child {
                        background: #151822;
                        a {
                            padding-left: 35px;
                            position: relative;
                            &::after {
                                content: '';
                                position: absolute;
                                width: 2px;
                                height: 2px;
                                left: 18px;
                                top: 19px;
                                background: #ccc;
                            }
                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .product-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }
        }
    }
    .product-navbar-collapse {
        position: absolute;
        left: 0;
        top: 50%;
        width: 20px;
        height: 50px;
        z-index: 3;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-transform: rotateY(180deg) translateY(-50%);
        -moz-transform: rotateY(180deg) translateY(-50%);
        -ms-transform: rotateY(180deg) translateY(-50%);
        -o-transform: rotateY(180deg) translateY(-50%);
        transform: rotateY(180deg) translateY(-50%);
        overflow: hidden;
        .product-navbar-collapse-bg {
            width: 0;
            height: 50px;
            position: absolute;
            top: 0;
            right: 0;
            left: auto;
            border-bottom: 9px solid transparent;
            border-left: none;
            border-right: 13px solid #030c25;
            border-top: 9px solid transparent;
            -o-transition: all 0.1s ease, 0.1s ease;
            -ms-transition: all 0.1s ease, 0.1s ease;
            -moz-transition: all 0.1s ease, 0.1s ease;
            -webkit-transition: all 0.1s ease, 0.1s ease;
        }
        &:hover .product-navbar-collapse-bg {
            // border-right-color: #d0d0d0;
            border-bottom: 8px solid transparent;
            border-left: none;
            border-right: 20px solid #030c25;
            border-top: 8px solid transparent;
        }
        &:hover .product-navbar-collapse-bar {
            right: 0;
            left: auto;
        }
        .product-navbar-collapse-bar {
            height: 50px;
            position: relative;
            right: -7px;
            left: auto;
            text-align: center;
            cursor: pointer;
            -o-transition: all 0.1s ease, 0.1s ease;
            -ms-transition: all 0.1s ease, 0.1s ease;
            -moz-transition: all 0.1s ease, 0.1s ease;
            -webkit-transition: all 0.1s ease, 0.1s ease;
            i {
                font-size: 15px;
                line-height: 50px;
                vertical-align: text-top;
                color: #ccc;
            }
        }
    }
}
.active {
    .product-navbar {
        .product-navbar-collapse {
            .product-navbar-collapse-bar {
                i {
                    color: #546478;
                }
            }
        }
    }
}
</style>
