<template>
    <div class="input-box">
        <div class="oldValue" v-if="row.options && row.options.oldValue">
            {{ row.options.oldValue }}
        </div>
        <template v-if="row.type == 'input'">
            <el-input
                style="width: 100%"
                v-model="result[rowkey]"
                :title="result[rowkey]"
                @change="changeFn"
                @blur="blurFn"
                @focus="focusFn"
                @clear="clearFn"
                @input="inputFn"
                :size="size || 'small'"
                :maxlength="row.options.maxlength"
                :id="row.options.id"
                :minlength="row.options.minlength"
                :placeholder="row.options.placeholder"
                :formatter="row.options.formatter"
                :parser="row.options.parser"
                :clearable="row.options.clearable || false"
                :disabled="row.options.disabled || rowDisabled || false"
                :show-word-limit="row.options.showWordLimit || false"
                :show-password="row.options.showPassword || false"
                :type="row.options.inputType || 'text'"
                :min="row.options.min"
                :max="row.options.max"
                :step="row.options.step"
                :autofocus="row.options.autofocus || false"
                @keyup="keyupFn($event, result[rowkey])"
            >
                <template #append v-if="!!row.options.unit">
                    <span
                        :style="
                            `width:30px; height:100%; display: flex; justify-content: center; align-items: center; padding:0px !important;`
                        "
                    >
                        {{ row.options.unit }}
                    </span>
                </template>
            </el-input>
        </template>
        <template v-if="row.type == 'textarea'">
            <div class="textarea-style">
                <el-input
                    v-model="result[rowkey]"
                    type="textarea"
                    @change="changeFn"
                    @blur="blurFn"
                    @focus="focusFn"
                    @clear="clearFn"
                    @input="inputFn"
                    :size="size || 'small'"
                    :id="row.options.id"
                    :placeholder="row.options.placeholder"
                    :rows="row.options.rows"
                    :disabled="row.options.disabled || false"
                    :show-word-limit="row.options.showWordLimit || false"
                    :autosize="row.options.autosize || false"
                    :maxlength="row.options.maxlength"
                    :minlength="row.options.minlength"
                    @keyup="keyupFn($event, result[rowkey])"
                ></el-input>
            </div>
        </template>
        <template v-if="row.type == 'cascader'">
            <div class="cascader">
                <el-cascader
                    style="width: 100%"
                    v-model="result[rowkey]"
                    :size="size || 'small'"
                    :options="row.options.options"
                    @change="changeFn"
                    @remove-tag="removeTagFn"
                    @blur="blurFn"
                    @focus="focusFn"
                    :id="row.options.id"
                    :placeholder="row.options.placeholder"
                    :disabled="row.options.disabled || rowDisabled || false"
                    :collapse-tags="row.options.collapseTags || false"
                    :collapse-tags-tooltip="row.options.collapseTagsTooltip || false"
                    :show-all-levels="row.options.allLevels || false"
                    :filterable="row.options.filterable || false"
                    :clearable="row.options.clearable || false"
                    :props="row.options.props"
                    :max-collapse-tags="row.options.maxCollapseTags"
                    :popper-class="row.options.popperClass || ''"
                    ref="cascader"
                ></el-cascader>
            </div>
        </template>
        <template v-if="row.type == 'select'">
            <div class="select-style">
                <el-select
                    style="width: 100%"
                    v-model="result[rowkey]"
                    :size="size || 'small'"
                    :id="row.options.id"
                    :placeholder="row.options.placeholder || '请选择'"
                    :disabled="row.options.disabled || rowDisabled || false"
                    :clearable="row.options.clearable || false"
                    :filterable="row.options.filterable || false"
                    :multiple="row.options.multiple || false"
                    :collapse-tags-tooltip="row.options.collapseTagsTooltip || false"
                    :collapse-tags="row.options.collapseTags || false"
                    :automatic-dropdown="row.options.automaticDropdown || false"
                    :multiple-limit="row.options.multipleLimit || 0"
                    :remote="row.options.remote || false"
                    :remote-method="row.options.remoteMethod || null"
                    :loading="row.options.loading || false"
                    :value-key="row.options.valueKey || 'value'"
                    @change="changeFn"
                    @blur="blurFn"
                    @focus="focusFn"
                    @clear="clearFn"
                    @remove-tag="removeTagFn"
                    @visible-change="visibleChangeFn"
                    :max-collapse-tags="row.options.maxCollapseTags"
                >
                    <el-option
                        v-for="(item, index) in row.options.options"
                        :key="index"
                        :label="
                            item[row.options?.props?.label] ||
                                item[row.options?.props?.value] ||
                                item
                        "
                        :value="item[row.options?.props?.value] || item"
                        :disabled="item.disabled || false"
                    >
                        <span style="float: left" v-if="item[row.options?.props?.leftLabel]">
                            {{ item[row.options?.props?.leftLabel] }}
                        </span>
                        <span
                            v-if="item[row.options?.props?.rightLabel]"
                            style="
                                float: right;
                                color: var(--el-text-color-secondary);
                                font-size: 12px;
                            "
                        >
                            {{ item[row.options?.props?.rightLabel] }}
                        </span>
                    </el-option>
                </el-select>
            </div>
        </template>

        <template v-if="row.type == 'radio'">
            <div class="radio-style">
                <el-radio-group
                    v-model="result[rowkey]"
                    @change="changeFn"
                    :placeholder="row.options.placeholder"
                    :disabled="row.options.disabled || rowDisabled"
                    :border="row.options.border || false"
                    :size="size || 'small'"
                >
                    <el-radio
                        v-for="item in row.options.options"
                        :key="item[row.options?.props?.value] || item"
                        :label="item[row.options?.props?.value] || item"
                        :style="row.options.style || ''"
                    >
                        {{ item[row.options?.props?.label] || item }}
                    </el-radio>
                </el-radio-group>
            </div>
        </template>
        <template v-if="row.type == 'number'">
            <div class="number-style">
                <el-input-number
                    v-model="result[rowkey]"
                    @change="changeFn"
                    @blur="blurFn"
                    @focus="focusFn"
                    :placeholder="row.options.placeholder"
                    :controls="row.options.controls"
                    :min="row.options.min || 0"
                    :max="row.options.max || 10000000000"
                    :step="row.options.step"
                    :step-strictly="row.options.stepStrictly || false"
                    :precision="row.options.precision || 0"
                    :size="size || 'small'"
                    :disabled="row.options.disabled || rowDisabled"
                    style="width: 100%"
                ></el-input-number>
            </div>
        </template>
        <template v-if="row.type == 'checkbox'">
            <div class="checkbox-style">
                <el-checkbox-group
                    v-model="result[rowkey]"
                    :placeholder="row.options.placeholder"
                    :default-value="row.options.defaultValue"
                    :size="size || 'small'"
                    :min="row.options.min || 0"
                    :max="row.options.max || 10000000"
                    :text-color="row.options.textColor || '#ffffff'"
                    :disabled="row.options.disabled || rowDisabled"
                    @change="changeFn"
                >
                    <el-checkbox
                        v-for="item in row.options.options"
                        :key="item[row.options.props.value]"
                        :label="item[row.options.props.value]"
                    >
                        {{ item[row.options.props.label] }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </template>
        <template v-if="row.type == 'date'">
            <el-date-picker
                v-model="result[rowkey]"
                style="width: 100%"
                @change="changeFn"
                @blur="blurFn"
                @focus="focusFn"
                :size="size || 'small'"
                :id="row.options.id"
                :readonly="row.options.readonly || false"
                :disabled="row.options.disabled || rowDisabled || false"
                :editable="row.options.editable || false"
                :clearable="row.options.clearable || true"
                :range-separator="row.options.separator || '至'"
                :placeholder="row.options.placeholder || '选择日期'"
                :start-placeholder="row.options.startPlaceholder || ''"
                :end-placeholder="row.options.endPlaceholder || ''"
                :type="row.options.dateType || 'date'"
                :format="row.options.format || 'yyyy-MM-dd'"
                :value-format="row.options.valueFormat || 'yyyy-MM-dd'"
                :align="row.options.type || 'left'"
                :validate-event="row.options.validateEvent || true"
                :picker-options="row.options.pickerOptions || ''"
                :disabled-date="row.options.disabledDate || null"
                :popper-class="row.options.popperClass || ''"
            ></el-date-picker>
        </template>
        <template v-if="row.type == 'time'">
            <el-time-picker
                v-model="result[rowkey]"
                @change="changeFn"
                @blur="blurFn"
                @focus="focusFn"
                :size="size || 'small'"
                :id="row.options.id"
                :readonly="row.options.readonly || false"
                :disabled="row.options.disabled || rowDisabled || false"
                :editable="row.options.editable || false"
                :clearable="row.options.clearable || false"
                :placeholder="row.options.placeholder || '选择时间'"
                :start-placeholder="row.options.startPlaceholder || ''"
                :end-placeholder="row.options.endPlaceholder || ''"
                :range-separator="row.options.separator || '-'"
                :is-range="row.options.placeholder || false"
                :arrow-control="row.options.arrowControl || false"
                :align="row.options.align || 'left'"
                :value-format="row.options.valueFormat || 'hh:mm:ss'"
                :start="row.options.start || '09:00'"
                :end="row.options.end || '18:00'"
                :step="row.options.step || '00:30'"
                :minTime="row.options.minTime || '00:00'"
                :maxTime="row.options.maxTime || ''"
                style="width: 100%"
            ></el-time-picker>
        </template>
        <template v-if="row.type == 'img'">
            <div class="img-style">
                <a
                    href="javascript:;"
                    @click="handlePreview({ url: result[rowkey], type: row.options.fileType })"
                >
                    <img
                        v-if="result[rowkey]"
                        :src="result[rowkey]"
                        style="width: 50px"
                        :alt="row.options.alt"
                    />
                    <i
                        v-else
                        style="font-size: 22px; color: #999"
                        class="el-icon-picture-outline"
                    ></i>
                </a>
            </div>
            <!-- <dialog-file-preview
                :list="previewDialog.fileList"
                :src="previewDialog.src"
                :ext="previewDialog.ext"
                v-model="previewDialog.visible"
                @close="closePreviewDialog"
            /> -->
        </template>
        <template v-if="row.type == 'switch'">
            <div class="switch-style">
                <el-switch
                    v-model="result[rowkey]"
                    :disabled="row.options.disabled || rowDisabled"
                    :active-color="row.options.activeColor"
                    :inactive-color="row.options.inactiveColor"
                    :active-text="row.options.activeText"
                    :inactive-text="row.options.inactiveText"
                    :active-value="row.options.activeValue"
                    :inactive-value="row.options.inactiveValue"
                    :active-icon-class="row.options.activeIconClass"
                    :inactive-icon-class="row.options.inactiveIconClass"
                    :validate-event="row.options.validateEvent || true"
                    :class="`sc-switch-${size}`"
                    @change="changeFn"
                ></el-switch>
            </div>
        </template>
        <template v-if="row.type == 'inputSearch'">
            <div class="inputSearch">
                <el-input
                    class="input-with-select"
                    v-model="result[rowkey]"
                    :title="result[rowkey]"
                    @change="changeFn"
                    @blur="blurFn"
                    @focus="focusFn"
                    @clear="clearFn"
                    @input="inputFn"
                    :size="size || 'small'"
                    :maxlength="row.options.maxlength"
                    :minlength="row.options.minlength"
                    :placeholder="row.options.placeholder"
                    :clearable="row.options.clearable || false"
                    :disabled="row.options.inputDisabled || rowDisabled || false"
                    :show-word-limit="row.options.showWordLimit || false"
                    :show-password="row.options.showPassword || false"
                ></el-input>
                <el-button
                    slot="append"
                    :size="size || 'small'"
                    :disabled="disabled || row.options.btnDisabled || rowDisabled || false"
                    @click="clickFn"
                    class="input-search-style"
                >
                    {{ row.options.btnText || '请选择' }}
                </el-button>
            </div>
        </template>
        <template v-if="row.type == 'selectSearch'">
            <div class="inputSearch">
                <el-select
                    class="input-with-select"
                    :size="size || 'small'"
                    :id="row.options.id"
                    :placeholder="row.options.placeholder || '请选择'"
                    :disabled="row.options.disabled || rowDisabled || false"
                    :clearable="row.options.clearable || false"
                    :filterable="row.options.filterable || false"
                    :multiple="row.options.multiple || false"
                    :collapse-tags-tooltip="row.options.collapseTagsTooltip || false"
                    :collapse-tags="row.options.collapseTags || false"
                    :automatic-dropdown="row.options.automaticDropdown || false"
                    :multiple-limit="row.options.multipleLimit || 0"
                    :remote="row.options.remote || false"
                    :remote-method="row.options.remoteMethod || null"
                    :loading="row.options.loading || false"
                    :value-key="row.options.valueKey || 'value'"
                    @change="changeFn"
                    @blur="blurFn"
                    @focus="focusFn"
                    @clear="clearFn"
                    @remove-tag="removeTagFn"
                    @visible-change="visibleChangeFn"
                >
                    <el-option
                        v-for="(item, index) in row.options.options"
                        :key="index"
                        :label="
                            item[row.options?.props?.label] ||
                                item[row.options?.props?.value] ||
                                item
                        "
                        :value="item[row.options?.props?.value] || item"
                        :disabled="item.disabled || false"
                    ></el-option>
                </el-select>
                <el-button
                    slot="append"
                    :size="size || 'small'"
                    :disabled="disabled || row.options.btnDisabled || rowDisabled || false"
                    @click="clickFn"
                    class="input-search-style"
                >
                    {{ row.options.btnText || '请选择' }}
                </el-button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        rowkey: {
            type: String,
            default: '',
        },
        row: {
            type: Object,
            default() {
                return {};
            },
        },
        size: {
            type: String,
            default: 'small',
        },
        rowDisabled: {
            type: Boolean,
            default: false,
        },
        inputBorder: {
            type: Boolean,
            default: false,
        },
        columnsPaddingRight: {
            type: Number || String,
            default: 0,
        },
        resultValidate: {
            type: Object,
            default() {
                return {};
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    name: 'base-input',
    data() {
        return {
            result: {},
            previewDialog: {
                fileList: [],
                visible: false,
                src: '',
                ext: '',
            },
            cascader: null,
        };
    },
    methods: {
        changeFn(data) {
            let nodeData = {};
            if (this.row.type == 'cascader') {
                nodeData = this.$refs.cascader.getCheckedNodes();
            }
            if (this.row.type == 'select' || this.row.type == 'cascader') {
                if (data.length > this.row.options.max) {
                    this.$message({
                        message: `最多只能选择${this.row.options.max}项`,
                        grouping: true,
                        type: 'error',
                    });
                    data = data.slice(0, this.row.options.max);
                    this.result[this.rowkey] = data;
                }
            }
            this.$emit('change', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        clickFn(data) {
            this.$emit('click', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        blurFn(data) {
            this.$emit('blur', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        focusFn(data) {
            this.$emit('focus', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        handlePreview({ url = '', type = '' }) {
            if (!url) {
                return;
            }
            this.previewDialog.visible = true;
            this.previewDialog.fileList = [url];
            this.previewDialog.src = url;
            this.previewDialog.ext = type;
        },
        closePreviewDialog() {
            this.previewDialog.visible = false;
            this.previewDialog.fileList = [];
            this.previewDialog.src = '';
        },
        clearFn(data) {
            this.$emit('clear', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        removeTagFn(data) {
            this.$emit('removeTag', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        inputFn(data) {
            this.$emit('input', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        visibleChangeFn(data) {
            this.$emit('visibleChange', {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
        keyupFn($event, data) {
            this.$emit('keyup', $event, {
                row: this.row,
                key: this.rowkey,
                value: data,
            });
        },
    },
    created() {},
    watch: {
        resultValidate: {
            handler(val, oldVal) {
                this.result = val;
            },
            immediate: true,
            deep: true,
        },
    },
    beforeMount() {
        // this.result = this.resultValidate;
    },
    mounted() {},
};
</script>

<style scoped lang="less">
.input-box {
    width: 100%;
}
.select-style {
}
:deep(.el-input) {
    width: 100%;
}
.inputSearch {
    display: flex;
}
.input-with-select {
    :deep(.el-input__wrapper) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }
}
.input-search-style {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}
:deep(.el-input__inner),
:deep(.el-button) {
    font-size: 13px;
}

:deep(.el-input-group__append) {
    padding: 0px;
}
</style>
