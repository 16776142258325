<template>
    <div class="jump-wrap">
        <img width="100%" :src="imgUrl" alt="" class="img" />
    </div>
</template>

<script>
const Pages = {
    index: 'pages/tabbar/index/index', //首页
    case: 'pages/tabbar/case/index', //案例
    designers: 'pages/tabbar/designers/index', //设计师
    hongxi: 'market/activity/hongxi/index', //红玺
    style: 'market/style/style', //风格测试
    match: 'market/match/match', //智能匹配
};
const urlObj = [
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg1.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg1.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg2.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg3.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg4.png?x-oss-process=image/resize,w_750',
];
export default {
    name: 'jumpScj',
    data() {
        return {
            imgUrl: urlObj[Math.floor(Math.random() * 4)],
        };
    },
    mounted() {
        const { path, key } = this.$route.query;
        const _path = Pages[path];
        if (_path && key) {
            window.location.href = `weixin://dl/business/?appid=wx182655b389c7d090&path=${_path}&query=sourceTG=${key}`;
        }
    },
    methods: {},
};
</script>

<style lang="less" scoped>
.jump-wrap {
    font-size: 0;
    .img {
        width: 100%;
        // height: 100vh;
        // object-fit: cover;
        // object-position: center top;
    }
}
.hidden {
    visibility: hidden;
}
</style>
