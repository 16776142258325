import rq from '@/utils/https.js';
const ajax = rq.requests;

// 获取广告位详情
export const addControl = data =>
    ajax({
        url: `/decoration/add-control`,
        method: 'put',
        data,
    });
// 页面回显
export const pageEcho = params =>
    ajax({
        url: `/decoration/page-echo/${params}`,
        method: 'get',
    });
// 编辑组件回显
export const editControlEcho = params =>
    ajax({
        url: `/decoration/edit-control-echo/${params}`,
        method: 'get',
    });
// 删除模块
export const moduleDelete = (mId, pId) =>
    ajax({
        url: `/decoration/module-delete/${mId}/${pId}`,
        method: 'delete',
    });
// 模块排序
export const moduleSort = data =>
    ajax({
        url: '/decoration/module-sort',
        method: 'post',
        data,
    });
// 编辑组件
export const editControl = data =>
    ajax({
        url: '/decoration/edit-control',
        method: 'post',
        data,
    });
// 编辑组件
export const getPages = params =>
    ajax({
        url: '/decoration/page-list',
        method: 'get',
        params,
    });
// 页面发布
export const pagePublish = params =>
    ajax({
        url: `/decoration/page-publish/${params}`,
        method: 'get',
    });
// 模块发布
export const setModulePublish = params =>
    ajax({
        url: '/decoration/page-publish',
        method: 'get',
        params,
    });
