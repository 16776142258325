<template>
    <div class="jump-wrap">
        <img width="100%" :src="imgUrl" alt="" class="img" />
    </div>
</template>

<script>
const schemeObj = {
    'mobile-sms-hongxi':
        'weixin://dl/business/?appid=wx182655b389c7d090&path=market/activity/hongxi/index&query=sourceTG=mobile-sms-hongxi',
    'mobile-sms-style':
        'weixin://dl/business/?appid=wx182655b389c7d090&path=market/style/style&query=sourceTG=mobile-sms-style',
    'mobile-sms-home':
        'weixin://dl/business/?appid=wx182655b389c7d090&path=pages/tabbar/index/index&query=sourceTG=mobile-sms-home',
};
const urlObj = [
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg1.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg1.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg2.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg3.png?x-oss-process=image/resize,w_750',
    'https://f.shangceng.com.cn/sczsHome/weixin/wx/icons/jump-scj-bg4.png?x-oss-process=image/resize,w_750',
];
export default {
    name: 'jumpSczshome',
    data() {
        return {
            name: '',
            imgUrl: '',
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // https://market.voglassdc.com/#/jump-sczshome?path=${}
            let query = decodeURIComponent(window.location.hash);
            let param = query.split('=')[1];
            console.log('--------param', param);
            if (param) {
                // 处理明文scheme跳转链接
                if (Object.keys(schemeObj).includes(param)) {
                    param = schemeObj[param];
                    this.imgUrl = urlObj[Math.floor(Math.random() * 4)];
                } else {
                    // 处理阿里云短信跳转链接
                    if (param.indexOf('http') == -1) {
                        param = (param && 'https://' + param) || '';
                    }
                    if (param.indexOf('-cn') > -1) {
                        param = param.replace(/-cn/g, '.cn');
                    }
                    if (param.indexOf('查看') > -1) {
                        param = param.replace('查看', '');
                    }
                }
                window.location.href = param;
            }
            console.log('jump-sczshome path', param);
        },
    },
};
</script>

<style lang="less" scoped>
.hidden {
    visibility: hidden;
}
</style>
