import Vue from 'vue';
import ElementUI from 'element-ui';
// import '@/assets/css/index.css';

import App from './App.vue';
import Vuex from 'vuex';
import { router } from './router/index.js';
import './assets/css/common.less';
import './assets/css/iconfont.css';

import { preventReClick } from './utils/util.js';
import store from './vuex'; //全局状态管理
import rq from '@/utils/https.js';
Vue.prototype.$ajax = rq.requests;
Vue.config.productionTip = false;

import horizontalScroll from 'el-table-horizontal-scroll';
Vue.use(horizontalScroll);
//main.js 引入 sc-form-ele:
import scFormEle from 'sc-form-ele';
import 'sc-form-ele/lib/sc-form-ele.css';

import scQrcodePopover from '@/page/mkt/components/sc-qrcode-popover';
Vue.component('scQrcodePopover', scQrcodePopover);
import baseForm from '@/page/mkt/components/base-form';
Vue.component('baseForm', baseForm);
Vue.use(scFormEle);
import './permission';

import hls from 'videojs-contrib-hls';
import 'video.js/dist/video-js.css';
Vue.use(hls);

Vue.use(ElementUI);
Vue.use(Vuex);

window.App = new Vue({
    beforeCreate() {
        // 给Vue原型对象指定事件总线对象(当前vm对象)
        Vue.prototype.$bus = this;
    },
    render: h => h(App),
    router,
    store,
    preventReClick,
}).$mount('#app');
