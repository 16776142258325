<template>
    <!-- 预约记录 -->
    <div class="item-box">
        <div class="top-line">
            <div class="tit">预约记录</div>
            <div class="right" v-if="active == '999' && list.length == 4">
                <el-button type="text" @click="handleCheckTab()" size="mini">
                    查看更多
                </el-button>
            </div>
        </div>
        <div class="infor-item">
            <sc-table-v2
                v-if="active == '3' || list.length"
                :head="tableHead"
                :data="list"
                :page="page"
                :page-hide="pageHide"
                :read-table="true"
                :load="load"
                :pop-show="false"
                :show-add="false"
                :table-height="tableHeightCalc"
                :selection="false"
                ref="refTable"
                @pageChange="pageChange"
                @pageSizesChange="pageSizesChange"
            >
                <!-- //插槽例子，指定某一列用插槽写入 -->
                <template v-slot:designerName>
                    <el-table-column label="设计师昵称">
                        <!-- <template slot="header">预约设计师</template> -->
                        <template slot-scope="scope">
                            <div style="display: flex; align-items: center">
                                <el-image
                                    v-if="scope.row.imgUrl"
                                    style="width: 50px; height: 50px"
                                    :src="scope.row.imgUrl"
                                    :preview-src-list="[scope.row.imgUrl]"
                                    fit="contain"
                                ></el-image>
                                &nbsp;
                                <span v-if="scope.row.designerName || scope.row.job">
                                    {{
                                        scope.row.designerName +
                                            ((scope.row.job && ' / ' + scope.row.job) || '')
                                    }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                </template>
            </sc-table-v2>
        </div>
    </div>
</template>

<script>
import { getuserAppointmentList } from '@/api/mkt/user.js';

export default {
    name: 'reservation-list',
    props: {
        tabActive: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            active: '',
            pageHide: true,
            tableHead: [
                { name: '预约时间', key: 'appointmentTime' },
                { name: '设计师昵称', key: 'designerName' },
                { name: '预约页面', key: 'pageBuryingPoint' },
                { name: '录入的称呼', key: 'callName' },
                { name: '创建时间', key: 'createTime' },
            ],
            tableHeightCalc: null,
            page: {
                size: 4,
                page: 1,
                count: 0,
            },
            list: [],
            load: false,
        };
    },
    watch: {
        tabActive: {
            immediate: true,
            handler(newVal) {
                this.list = [];
                this.active = newVal;
                if (newVal == '999') {
                    this.tableHeightCalc = null;
                    this.page.size = 4;
                    this.pageHide = true;
                } else if (newVal == '3') {
                    this.tableHeightCalc = window.innerHeight - 500;
                    this.page.size = 30;
                    this.pageHide = false;
                }
                console.log('-----------222222222222222222', this.$route.query.token);
                if (this.$route.query.token) {
                    setTimeout(async () => {
                        this.getuserAppointmentList();
                    }, 800);
                } else {
                    this.getuserAppointmentList();
                }
            },
        },
    },

    methods: {
        async getuserAppointmentList() {
            let params = {
                page: this.page.page,
                size: this.page.size,
                accountId: this.$route.query.accountId,
            };
            this.load = true;
            let { code, data } = await getuserAppointmentList(params);
            if (code === 200) {
                this.load = false;
                this.list = data.children;
                this.page.count = data.total;
                data.total && this.$emit('setTabNum', { type: 3, num: data.total });
            } else {
                this.load = false;
            }
        },
        pageChange(val) {
            this.page.page = val;
            this.getAppointmentList();
            this.$nextTick(() => {
                this.$refs.refTable.$refs['table-box'].bodyWrapper.scrollTop = 0;
            });
        },
        pageSizesChange(val) {
            this.page.size = val;
            this.getAppointmentList();
        },
        // 切换tab
        handleCheckTab() {
            this.$parent.handleClick({ label: '预约记录', name: '3' });
        },
    },
};
</script>

<style lang="less" scoped>
.item-box {
    margin-bottom: 20px;
    .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7d7d7;
        line-height: 34px;
        .tit {
            color: #d9001b;
        }
    }
    .infor-item {
        margin-top: 10px;
    }
}
</style>
