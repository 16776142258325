import rq from '@/utils/https.js';
const $ajax = rq.requests;
import { BPMURL } from '@/config.js';

// 获取话题列表
export const getList = params =>
    $ajax({
        url: '/topic/page',
        method: 'get',
        params,
    });
// 获取栏目列表
export const getColumnId = params =>
    $ajax({
        url: '/columns/list',
        method: 'get',
        params,
    });
// 获取分类列表
export const getCategoryList = params =>
    $ajax({
        url: '/category/list',
        method: 'get',
        params,
    });
// 获取标签组列表
export const getLabellist = params =>
    $ajax({
        url: '/labels/group/list',
        method: 'get',
        params,
    });
// 获取普通标签组列表
export const getLabelCommonList = params =>
    $ajax({
        url: '/labels/common/list',
        method: 'get',
        params,
    });
// 新增编辑话题
export const saveTopic = data =>
    $ajax({
        url: '/topic/save',
        method: 'post',
        data,
    });
// 判断话题是否存在
export const existTopic = params =>
    $ajax({
        url: '/topic/exist',
        method: 'get',
        params,
    });
// 获取来源
export const getSource = params =>
    $ajax({
        url: '/dict/list',
        method: 'get',
        params,
    });
export const onIsEnabled = data => {
    return $ajax({
        url: '/topic/update/enabled?enabled=' + data.enabled + '&topicId=' + data.topicId,
        method: 'put',
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
};
// 删除话题
export const removeTopic = data =>
    $ajax({
        url: '/topic/remove?topicId=' + data.topicId,
        method: 'delete',
        data,
        headers: {
            ContentType: 'application/x-www-form-urlencoded',
        },
    });
// 话题详情
export const topicDetail = params =>
    $ajax({
        url: '/topic/detail',
        method: 'get',
        params,
    });

export default {};
