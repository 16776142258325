import createPersistedState from 'vuex-persistedstate';
// import { resetRouter, router } from '../router';
import { router } from '../router';
import { filterAsyncRouter } from '@/utils/util.js';
//数据存放
export default {
    state: {
        login: {
            username: '',
            password: '',
        },
        checked: false,
        pageTitle: '',
        moduleKey: '',
        order: {
            list: {
                dialog: {
                    'back-money': false,
                    'replace-people': false,
                    'result-set': false,
                    'serve-tel': false,
                },
            },
        },
        userInfo: {
            username: '', //登录账号
            avatarPath: '', //头像
            nickName: '', //姓名
            phone: '', //手机号
            description: '', //描述
            routerList: [],
            id: '', //用户id
        },
    },

    //状态改变
    mutations: {
        setLogin(state, name) {
            state.login = name;
        },
        setChecked(state, checked) {
            state.checked = checked;
        },
        setPageTitle(state, name) {
            state.pageTitle = name;
        },
        setModuleKey(state, name) {
            state.moduleKey = name;
        },
        orderDialog(state, obj) {
            console.log(obj, 'vuex');
            state.order.list.dialog[obj.key] = obj.val;
        },
        SET_USER_INFO(state, val) {
            state.userInfo = val;
        },
        ADD_ROUTE(state) {
            let routerList = JSON.parse(JSON.stringify(state.userInfo.routerList));
            // console.log(router.getRoutes(), '路由列表'); //这里要注意精确固定数量。区别固定路由 和动态路由的加载
            if (router.getRoutes().length == 9) {
                routerList = filterAsyncRouter(routerList);
                routerList.forEach(i => {
                    // console.log('----------------------------------addRoute', i);
                    router.addRoute(i);
                });
            }
        },
    },

    actions: {
        login({ commit }, userInfo) {
            // const { username, password, routerList, id } = userInfo;
            const { username, routerList, id } = userInfo;
            return new Promise(resolve => {
                // 根据登录人权限设置菜单

                commit('SET_USER_INFO', {
                    username,
                    routerList,
                    id,
                });
                //添加路由
                commit('ADD_ROUTE');
                resolve();
            });
        },

        //注销
        logout({ commit, state }) {
            return new Promise(resolve => {
                commit('SET_USER_INFO', {
                    username: '',
                    password: '',
                    routerList: [],
                    id: '',
                });
                resolve();
            });
        },
        //添加路由
        addRoute({ commit }) {
            commit('ADD_ROUTE');
        },
    },
    plugins: [createPersistedState()],
};
//对象暴露
// export default new Vuex.Store({
//     state,
//     mutations,
//     actions,
//     plugins: [createPersistedState()],
// });
