import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 获取标签组列表
export const getTagList = data =>
    $ajax({
        url: '/works/list',
        method: 'post',
        data,
    });
// /works/info
export const info = params =>
    $ajax({
        url: '/works/info',
        method: 'get',
        params,
    });

//  /labels/operationLabel/list  运营标签
export const operationLabel = params =>
    $ajax({
        url: '/labels/operation/list',
        method: 'get',
        params,
    });
//  /works/approval  审核案例
export const approval = data =>
    $ajax({
        url: '/works/works-supplement',
        method: 'post',
        data,
    });
// 补充接口
export const worksEdit = data =>
    $ajax({
        url: '/works/works-supplement',
        method: 'post',
        data,
    });
// 驳回
export const approvalReject = data =>
    $ajax({
        url: '/works/approvalv31',
        method: 'post',
        data,
    });

// 新增标签组
export const addTag = data =>
    $ajax({
        url: '/design/labels-data/edit-label-library',
        method: 'post',
        data,
    });

// 编辑标签组
export const editTag = data =>
    $ajax({
        url: '/design/labels-data/edit-label-library',
        method: 'post',
        data,
    });

// 编辑标签组状态
export const editTagState = data =>
    $ajax({
        url: '/design/labels-data/edit-label-library-state',
        method: 'post',
        data,
    });

// 删除标签组
export const deleteTag = data =>
    $ajax({
        url: '/design/labels-data/del-label-library',
        method: 'post',
        data,
    });

// 获取适用栏目
export const getCloumn = params =>
    $ajax({
        url: '/dpapi/v1/LabelLibrary/GetColumnData',
        method: 'get',
        params,
    });

// 表格数据验证
export const excelDataExperimental = params =>
    $ajax({
        url: '/works/excel-data-experimental',
        method: 'get',
        params,
    });

// 批量添加案例
export const batchWorks = data =>
    $ajax({
        url: '/works/batch-works',
        method: 'post',
        data,
    });

//下载模板
export const downloadTemplate = params =>
    $ajax({
        url: '/works/download-excel',
        method: 'get',
        params,
    });
// 添加官网-设计师
export const uploadOfficialDesigner = params =>
    $ajax({
        url: '/works/official-designer-excel',
        method: 'get',
        params,
    });
// 添加官网-案例
export const uploadOfficialCase = params =>
    $ajax({
        url: '/works/official-case-excel',
        method: 'get',
        params,
    });
// 添加官网-案例内容
export const uploadOfficialCaseInfo = params =>
    $ajax({
        url: '/works/official-case-info-excel',
        method: 'get',
        params,
    });

//下载错误数据
export const downloadErrorDesigner = params =>
    $ajax({
        url: '/works/download-designer-info-error',
        method: 'get',
        params,
        responseType: 'blob',
    });
export const downloadErrorCase = params =>
    $ajax({
        url: '/works/download-case-error',
        method: 'get',
        params,
        responseType: 'blob',
    });
export const downloadErrorCaseInfo = params =>
    $ajax({
        url: '/works/download-case-info-error',
        method: 'get',
        params,
        responseType: 'blob',
    });

// 2.2
// /dict/getHouseLayoutList/{version}
export const getHouseLayoutList = params =>
    $ajax({
        url: '/dict/v2.2/getHouseLayoutList',
        method: 'get',
        params,
    });
// 2.5
export const getWorksOptions = params =>
    $ajax({
        url: '/works/v25/getWorksOptions',
        method: 'get',
        params,
    });
import { BPMURL } from '@/config.js';
// https://testbpm.voglassdc.com/CRMCORE/v1/AppCustomer/GetMarketAppProjectList?keyword=&page=1&limit=20
export const GetMarketAppProjectList = params =>
    $ajax({
        url: BPMURL + '/CRMCORE/v1/AppCustomer/GetMarketAppProjectList',
        method: 'get',
        params,
    });
// /space/list
export const getSpaceList = params =>
    $ajax({
        url: '/space/list',
        method: 'get',
        params,
    });
// /works/v25/get-space
export const getDefaultSpace = params =>
    $ajax({
        url: '/works/v25/get-space',
        method: 'get',
        params,
    });
// /works/v25/draft
export const draft = params =>
    $ajax({
        url: '/works/v25/draft',
        method: 'get',
        params,
    });
// /works/v25/save
export const worksSave = data =>
    $ajax({
        url: '/works/v25/save',
        method: 'post',
        data,
    });
// /user/base64-to-oss
export const baseToOss = data =>
    $ajax({
        url: '/user/base64-to-oss',
        method: 'post',
        data,
    });
// /user/oss-to-base64
export const ossToBase = data =>
    $ajax({
        url: '/user/oss-to-base64',
        method: 'post',
        data,
    });
// /space/save-custom-space 添加自定义空间
export const saveCustomSpace = data =>
    $ajax({
        url: '/space/save-custom-space',
        method: 'post',
        data,
    });
// 编辑案例是删除空间、
export const delWorksSpace = params =>
    $ajax({
        url: '/works/sys-del-works-space',
        method: 'get',
        params,
    });

// 编辑案例时删除分组、
export const delWorksGroup = params =>
    $ajax({
        url: '/works/sys-del-works-group',
        method: 'get',
        params,
    });
// 编辑案例时删除图片、
export const delWorksFile = params =>
    $ajax({
        url: '/works/sys-del-works-file',
        method: 'get',
        params,
    });

//
// /works/sys-top
export const sysTop = data =>
    $ajax({
        url: '/works/sys-top',
        method: 'post',
        data,
    });
export const hideExhibit = data =>
    $ajax({
        url: '/works/isShowWorks',
        method: 'post',
        data,
    });
// /works/get-sys-awards
export const getSysAwards = params =>
    $ajax({
        url: '/works/get-sys-awards',
        method: 'get',
        params,
    });
export default {};
