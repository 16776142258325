<template>
    <el-popover
        placement="top-start"
        title="微信扫一扫"
        width="200"
        trigger="click"
        @show="getData"
    >
        <!-- "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" -->
        <el-image v-if="qrCodeUrl" fit="cover" :src="qrCodeUrl"></el-image>
        <div class="no-image" v-else></div>
        <el-button type="text" slot="reference" :size="size" style="margin-left: 5px">
            {{ tit }}
        </el-button>
    </el-popover>
</template>

<script>
import { getQrcode } from '@/api/mkt/category.js';
export default {
    name: '',
    props: {
        id: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        tit: {
            type: String,
            default: '二维码',
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data() {
        return {
            qrCodeUrl: '',
            pages: {
                exhibit: 'market/case/details/details',
                'exhibit-group': 'mkt/pages/mkt/works/group/index',
                article: 'mkt/pages/mkt/article/article/index',
                'article-image': 'market/article/article-image/article-image',
                topic: 'mkt/pages/mkt/topic/index',
                user: 'market/my/designer/designer', // 设计师
                intelligent: 'market/match/match', // 智能匹配
                style: 'market/style/style', // 风格测试
            },
            queryObj: {
                exhibit: 'id',
                'exhibit-group': 'inspirationId', //inspirationId 灵感集id   worksId 案例id
                article: 'articleId',
                topic: 'topicId',
                user: 'accountId',
            },
        };
    },
    mounted() {},
    methods: {
        async getData() {
            let page = this.pages[this.type] || '';
            let param = 'id' + '=' + this.id;
            if (!this.id) {
                return;
            }
            let params = {
                page,
                param,
                width: 600,
            };
            console.log('---------------------', params);

            let res = await getQrcode(params);
            console.log(res);
            if (res.code == 200) {
                this.qrCodeUrl = res.data;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.no-image {
    min-height: 150px;
    min-width: 150px;
}
</style>
