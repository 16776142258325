<template>
    <div class="table">
        <el-form
            ref="ruleFormRef"
            label-width="0"
            :model="resultValidate"
            :validate-on-rule-change="false"
            :disabled="disabled"
            :label-position="labelPosition"
            @submit.native.prevent
        >
            <el-descriptions :column="columnsNum" :direction="direction" :colon="colon">
                <template slot="title">
                    <div
                        v-if="title"
                        :class="size"
                        class="title-s"
                        style="margin-bottom: 16px; margin-top: 21px"
                    >
                        <span class="hong-icon"></span>
                        {{ title }}
                    </div>
                </template>
                <template slot="extra">
                    <slot name="extra"></slot>
                </template>
                <template v-for="(item, key) in columns">
                    <el-descriptions-item
                        v-if="item.type != 'hidden' && direction != 'vertical'"
                        :key="key"
                        :span="item.spanCol || 0"
                        width="400px"
                        :class-name="['item-box', setItemName(item)]"
                        label-class-name="label-class-name"
                    >
                        <template slot="label">
                            <template v-if="item.type == 'title'">
                                <div class="columns-title">{{ item.name }}</div>
                            </template>
                            <template v-else>
                                <div class="label-box" :style="LS">
                                    <i v-if="item.icon" :class="item.icon"></i>
                                    <span>{{ item.name }}</span>
                                    <span
                                        class="must"
                                        v-if="item.rules && item.rules.length > 0"
                                    ></span>
                                    <el-tooltip v-if="item.tooltip" placement="top" effect="light">
                                        <template slot="content">
                                            <div class="tooltips">
                                                {{ item.tooltip }}
                                            </div>
                                        </template>
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </div>
                            </template>
                        </template>
                        <div
                            :class="['value-box', `value-box-col-${item.spanCol || 1}`]"
                            :style="CS"
                        >
                            <slot :name="key">
                                <template v-if="item.type == 'text'">
                                    <div
                                        class="value-box-text value"
                                        :title="item.options?.showTitle ? result[key] : ''"
                                    >
                                        {{
                                            item.options?.formatter
                                                ? item.options?.formatter(
                                                      item.showValue || result[key]
                                                  )
                                                : item.showValue || result[key]
                                        }}
                                    </div>
                                </template>
                                <template v-else>
                                    <template v-if="item.rules && item.rules.length > 0">
                                        <el-form-item :prop="key" :rules="item.rules">
                                            <sc-input
                                                :rowkey="key"
                                                :row="item"
                                                :resultValidate="result"
                                                :size="size || 'small'"
                                                :uploadConfig="uploadConfig"
                                                :columnsPaddingRight="columnsPaddingRight"
                                                :disabled="disabled"
                                                @change="changeFn"
                                                @click="clickFn"
                                                @focus="inputFocusHandle"
                                                @blur="inputBlurHandle"
                                                @input="inputHandle"
                                                @removeTag="removeTag"
                                                @visibleChange="visibleChange"
                                                @keyup="keyupFn"
                                            />
                                        </el-form-item>
                                    </template>
                                    <template v-else>
                                        <sc-input
                                            :rowkey="key"
                                            :row="item"
                                            :resultValidate="result"
                                            :size="size || 'small'"
                                            :uploadConfig="uploadConfig"
                                            :columnsPaddingRight="columnsPaddingRight"
                                            :disabled="disabled"
                                            :rowDisabled="disabled"
                                            @change="changeFn"
                                            @click="clickFn"
                                            @focus="inputFocusHandle"
                                            @blur="inputBlurHandle"
                                            @input="inputHandle"
                                            @removeTag="removeTag"
                                            @visibleChange="visibleChange"
                                            @keyup="keyupFn"
                                        />
                                    </template>
                                </template>
                            </slot>
                        </div>
                    </el-descriptions-item>

                    <!-- 纵向STR -->
                    <el-descriptions-item
                        v-if="item.type != 'hidden' && direction == 'vertical'"
                        :key="key"
                        :span="item.spanCol || 0"
                        width="400px"
                        :class-name="['item-box', setItemName(item)]"
                    >
                        <template slot="label">
                            <template v-if="item.type == 'title'">
                                <div class="columns-title">{{ item.name }}</div>
                            </template>
                            <template v-else>
                                <div class="label-box" :style="LS">
                                    <i v-if="item.icon" :class="item.icon"></i>
                                    <span class="form-label">{{ item.name }}</span>
                                    <span
                                        class="must"
                                        v-if="item.rules && item.rules.length > 0"
                                    ></span>
                                    <el-tooltip v-if="item.tooltip" placement="top" effect="light">
                                        <template slot="content">
                                            <div class="tooltips">
                                                {{ item.tooltip }}
                                            </div>
                                        </template>
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </div>
                            </template>
                        </template>
                        <div
                            :class="['value-box', `value-box-col-${item.spanCol || 1}`]"
                            :style="CS"
                        >
                            <slot :name="key">
                                <template v-if="item.type == 'text'">
                                    <div
                                        class="value-box-text"
                                        :title="item.options?.showTitle ? result[key] : ''"
                                    >
                                        {{
                                            item.options?.formatter
                                                ? item.options?.formatter(
                                                      item.showValue || result[key]
                                                  )
                                                : item.showValue || result[key]
                                        }}
                                    </div>
                                </template>
                                <template v-else>
                                    <template v-if="item.rules && item.rules.length > 0">
                                        <el-form-item :prop="key" :rules="item.rules">
                                            <sc-input
                                                :rowkey="key"
                                                :row="item"
                                                :resultValidate="result"
                                                :size="size || 'small'"
                                                :uploadConfig="uploadConfig"
                                                :columnsPaddingRight="columnsPaddingRight"
                                                :disabled="disabled"
                                                @change="changeFn"
                                                @click="clickFn"
                                                @focus="inputFocusHandle"
                                                @blur="inputBlurHandle"
                                                @input="inputHandle"
                                                @removeTag="removeTag"
                                                @visibleChange="visibleChange"
                                                @keyup="keyupFn"
                                            />
                                        </el-form-item>
                                    </template>
                                    <template v-else>
                                        <sc-input
                                            :rowkey="key"
                                            :row="item"
                                            :resultValidate="result"
                                            :size="size || 'small'"
                                            :uploadConfig="uploadConfig"
                                            :columnsPaddingRight="columnsPaddingRight"
                                            :disabled="disabled"
                                            @change="changeFn"
                                            @click="clickFn"
                                            @focus="inputFocusHandle"
                                            @blur="inputBlurHandle"
                                            @input="inputHandle"
                                            @removeTag="removeTag"
                                            @visibleChange="visibleChange"
                                            @keyup="keyupFn"
                                        />
                                    </template>
                                </template>
                            </slot>
                        </div>
                    </el-descriptions-item>
                    <!-- END纵向 -->
                </template>
            </el-descriptions>
        </el-form>
    </div>
</template>

<script>
import scInput from './base-input.vue';
export default {
    name: 'base-form',
    components: { scInput },
    props: {
        labelPosition: {
            type: String,
            default: 'right',
        },
        size: {
            type: String,
            default: 'default',
        },
        name: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        tablename: {
            type: String,
            default: 'sc-form-box',
        },
        columnsNum: {
            type: Number,
            default: 3,
        },
        columns: {
            type: Object,
        },
        columnsPaddingRight: {
            type: Number,
            default: 0,
        },
        inputStyle: {
            type: Object,
            default() {
                return {};
            },
        },
        result: {
            type: Object,
            default() {
                return {};
            },
        },
        uploadConfig: {
            type: Object,
            default() {
                return {};
            },
        },
        direction: {
            type: String,
            default: 'horizontal',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        LS: {
            type: Object,
            default() {
                return {};
            },
        },
        CS: {
            type: Object,
            default() {
                return {};
            },
        },
        colon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            resultValidate: {},
        };
    },

    methods: {
        setItemName(item) {
            const name = item.name;
            if (!name) {
                return '';
            }
            if (name.charAt(name.length - 1) === '：') {
                return `item-box-${name.length - 1 > 0 ? item.name.length - 1 : 0}`;
            }
            return `item-box-${name.length}`;
        },
        changeFn(data) {
            this.$emit('change', data);
        },
        clickFn(data) {
            this.$emit('click', data);
        },
        inputFocusHandle(data) {
            this.$emit('inputFocus', data);
        },
        inputBlurHandle(data) {
            this.$emit('inputBlur', data);
        },
        inputHandle(data) {
            this.$emit('input', data);
        },
        removeTag(data) {
            this.$emit('removeTag', data);
        },
        visibleChange(data) {
            this.$emit('visibleChange', data);
        },
        keyupFn($event, data) {
            let emitName = 'keyup';
            if ($event.keyCode == 13) {
                emitName = 'keyupEnter';
            }
            data.keyCode = $event.keyCode;
            this.$emit(emitName, data);
        },
        validateAll(callback) {
            return new Promise((resolve, reject) => {
                this.$refs.ruleFormRef.validate(valid => {
                    if (valid) {
                        resolve(valid);
                    } else {
                        callback(false);
                    }
                });
            });
        },

        resetForm() {
            this.$refs.ruleFormRef.resetFields();
        },
    },
    watch: {
        result: {
            handler(val) {
                this.resultValidate = { ...val };
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style scoped lang="less">
.table {
    .must {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #ff0200;
        border-radius: 50%;
        color: #ff0200;
        // vertical-align: -2px;
        margin-left: 8px;
    }
}
.texts {
    color: #999999;
    min-height: 30px;
    line-height: 30px;
    padding-left: 15px;
    line-height: 30px;
    word-wrap: break-word;
    position: relative;
    cursor: no-drop;
}
/deep/ .item-box {
    vertical-align: 0px;
    font-size: 13px;
}
.textRed {
    color: red;
}
.tooltips {
    width: 200px;
}
/deep/ .el-descriptions__header {
    margin-bottom: 0;
}
/deep/ .el-descriptions__title {
    font-size: 15px;
    color: #333;
}
/deep/ .el-descriptions__cell {
    width: 100%;
    overflow: hidden;
}

/deep/ .label-class-name {
    display: inline-block;
    text-align: right;
    vertical-align: top;
    padding-top: 5px;
    font-size: 13px;
    margin-right: 4px;
}
/deep/ .el-form-item__error {
    padding-top: 1px;
}
.label-box {
    min-width: 80px;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.value-box {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    .value-box-text {
        padding-top: 5px;
        overflow-wrap: anywhere;
    }
}
.el-form-item {
    margin-bottom: 0;
}
.title-s {
    display: flex;
    justify-content: left;
    align-items: center;

    .hong-icon {
        display: block;
        width: 2px;
        height: 16px;
        background: #b64242;
        margin-right: 10px;
    }
}
.label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #ababab;
    line-height: 21px;
    text-align: justify;
    font-style: normal;
}
.value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    line-height: 21px;
    text-align: justify;
    font-style: normal;
}
.columns-title {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 700;
    color: #303133;
}
.form-label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: justify;
    font-style: normal;
}
.mini {
    font-size: 12px;
}
.small {
    font-size: 13px;
}
.medium {
    font-size: 14px;
}
</style>
