import rq from '@/utils/https.js';
const $ajax = rq.requests;

// 获取话题列表
export const getList = params =>
    $ajax({
        url: '/specialSubject/list',
        method: 'get',
        params,
    });
// 新增专题
export const saveTopic = data =>
    $ajax({
        url: '/specialSubject/save',
        method: 'post',
        data,
    });
// 获取分公司列表
export const getCompany = data =>
    $ajax({
        data,
        url: '/company/list',
        method: 'get',
    });
// 获取内容列表
export const getContentList = data =>
    $ajax({
        data,
        url: '/specialSubject/getContentList',
        method: 'get',
    });
// 修改是否启用
export const onIsEnabled = id =>
    $ajax({
        url: `/specialSubject/updateEnabled/${id}`,
        method: 'put',
    });
// 删除专题
export const removeArticle = id =>
    $ajax({
        url: `/specialSubject/${id}`,
        method: 'delete',
    });
// 获取专题文章列表
export const getArticleList = data =>
    $ajax({
        data,
        url: '/specialSubject/getArticleList',
        method: 'get',
    });
export default {};
