import axios from 'axios';
import { getCookie } from './util.js';
import { Message } from 'element-ui';
import { router } from '@/router/index.js';

let createData = { timeout: 600000 }; /// 请求超时时间
let createDataMKT = { timeout: 600000 }; /// 请求超时时间
let url = 'https://testmarket.voglassdc.com/api/admin/';
let urlMKT = 'https://testhome.voglassdc.com/admin/';
if (!location.host.includes('localhost')) {
    switch (location.host) {
        case 'testmarket.voglassdc.com':
            url = 'https://testmarket.voglassdc.com/api/admin/';
            break;
        case 'premarket.voglassdc.com':
            url = 'https://premarket.voglassdc.com/api/admin/';
            break;
        case 'market1.voglassdc.com':
            url = 'https://market1.voglassdc.com/api/admin/';
            break;
        case 'market.voglassdc.com':
            url = 'https://market.voglassdc.com/api/admin/';
            break;
        default:
            url = 'https://testmarket.voglassdc.com/'; //本地localhost
            break;
    }
    createData.baseURL = url;
    createDataMKT.baseURL = urlMKT;
}

// 创建 axios 实例

const requests = axios.create(createData);
const requestsMKT = axios.create(createDataMKT);

// 错误处理函数
const err = error => {
    if (error.response) {
        if (error.response.status === 403) {
            // Notify({ type: 'danger', message: data.message||data.msg });
        }
        if (error.response.status === 401) {
            Message.closeAll();
            Message({
                type: 'error',
                message: '非法请求,请登录',
                customClass: 'errorMsg',
            });
            router.push({ path: '/login' });
        }
    }
    return Promise.reject(error);
};
// request interceptor(请求拦截器)
requests.interceptors.request.use(config => {
    // config.withCredentials = false;
    config.headers['Authorization'] = getCookie('AuthorizationBe') || 1; // 让每个请求携带自定义 token 请根据实际情况自行修改
    // config.headers.put['content-Type'] = 'application/json';
    config.headers['custom'] = getCookie('account');
    if (config.contentType || config.headers.contentType) {
        //支持post formData类型
        config.headers['content-type'] = config.contentType || config.headers.contentType;
        if (Object.keys(config.data || {}).length) {
            config.data = getFormDate(config.data);
        }
    }
    return config;
}, err);

// response interceptor（接收拦截器）
requests.interceptors.response.use(response => {
    const res = response.data;
    if ((res.errorCode === 0 && !res.isSuccess) || (res.errorCode && res.errorCode !== 200)) {
        if (res.message) {
            Message.closeAll();
            Message({
                type: 'error',
                message: res.message,
                customClass: 'errorMsg',
                duration: 5000,
            });
        }
        if (res.errorCode == 30001 || res.errorCode == 401) {
            router.push({ path: '/login' });
            return res;
        }
        return false;
        // return Promise.reject(res);
    } else {
        if (res.errorCode) {
            res.code = res.errorCode;
        }
        return res;
    }
}, err);

// request interceptor(请求拦截器)
requestsMKT.interceptors.request.use(config => {
    // config.withCredentials = false;
    config.headers['Authorization'] = getCookie('AuthorizationBe') || 1; // 让每个请求携带自定义 token 请根据实际情况自行修改
    // config.headers.put['content-Type'] = 'application/json';
    return config;
}, err);

// response interceptor（接收拦截器）
requestsMKT.interceptors.response.use(response => {
    const res = response.data;

    if (res.errorCode && res.errorCode !== 200) {
        if (res.msg) {
            Message.closeAll();
            Message({
                type: 'error',
                message: res.msg,
                customClass: 'errorMsg',
                duration: 5000,
            });
        }
        if (res.errorCode == 30001 || res.errorCode == 401) {
            router.push({ path: '/login' });
            return res;
        }

        return Promise.reject(res);
    } else {
        res.code = res.errorCode;
        return res;
    }
}, err);
// json转formdate数据
const getFormDate = data => {
    let ret = '';
    for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    }
    return ret;
};

export default {
    requests,
    requestsMKT,
};
