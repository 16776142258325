import { getPages as getData } from '@/api/mkt/markets.js';

export default {
    state: {
        params: {
            companyCode: '', //默认传空
            page: 1,
            size: 100,
        },
        list: [],
        topObj: {},
    },
    mutations: {
        setParams(state, data) {
            state.params = data;
        },
        setList(state, data) {
            state.list = data;
        },
        setTopObj(state, data) {
            state.topObj = data;
        },
        clear(state) {
            state.list = [];
        },
    },
    actions: {
        list({ commit }) {
            // console.log(this.state['mkt-pages'].params, '---------------');
            if (!getData) {
                return false; //
            }
            return new Promise(resolve => {
                getData(this.state['mkt-pages'].params).then(res => {
                    if (res.code === 200) {
                        res.data.children.map(item => {
                            Object.keys(item).forEach(items => {
                                if (item[items] === null || item[items] === '') {
                                    item[items] = '--';
                                }
                            });
                        });
                        let _res = res.data.children;
                        let data = _res || [];
                        data.map(d => (d.pageType = '系统页面'));
                        commit('setList', data);
                        resolve(data);
                    }
                });
            });
        },
    },
};
