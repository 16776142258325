import { getTagList as getData } from '@/api/mkt/tag.js';
export default {
    state: {
        params: {},
        page: {
            count: '',
            page: 1,
        },
        pagination: {
            page: 1,
            size: 30,
            count: 0,
        },
        list: [],
    },
    mutations: {
        setParams(state, data) {
            state.params = { ...state.params, ...data };
        },
        setList(state, data) {
            state.list = data;
        },
        setTotal(state, data) {
            state.pagination.count = data.total;
        },
        clear(state) {
            state.list = [];
        },
        SET_PANAGATION(state, data) {
            state.pagination = {
                page: data.page || state.pagination.page,
                size: data.size || state.pagination.size,
                count: state.pagination.count,
            };
        },
    },
    actions: {
        list({ state, commit }) {
            let params = {
                ...this.state['mkt-tag'].params,
                page: state.pagination.page,
                size: state.pagination.size,
            };

            if (!getData) {
                return false; //
            }
            return new Promise(resolve => {
                getData(params).then(res => {
                    console.log('----------getData', res);
                    // if (res.code === 200) {
                    res.data.children.map(item => {
                        Object.keys(item).forEach(items => {
                            if (item[items] === null || item[items] === '') {
                                item[items] = '--';
                            }
                        });
                    });
                    let data = res.data || [];
                    // if (data.length > 0) {
                    commit('setList', data.children);
                    commit('setTotal', res.data);
                    resolve(data);
                    // }
                    // }
                });
            });
        },
    },
};
