import { render, staticRenderFns } from "./Jump-scj.vue?vue&type=template&id=13538495&scoped=true"
import script from "./Jump-scj.vue?vue&type=script&lang=js"
export * from "./Jump-scj.vue?vue&type=script&lang=js"
import style0 from "./Jump-scj.vue?vue&type=style&index=0&id=13538495&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13538495",
  null
  
)

export default component.exports