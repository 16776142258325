<template>
    <div id="app">
        <div id="product-navbar" class="product-main">
            <div class="g-body">
                <transition name="move" mode="out-in" class="el-main">
                    <router-view key="Bear"></router-view>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            menu: true,
            breadShow: true,
            path: '',
        };
    },
    watch: {},
    components: {},
    mounted() {},
    methods: {},
};
</script>

<style lang="less" scoped></style>
